import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Cancel, Edit } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import MaterialTable, { MTableBodyRow } from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import format from "date-fns/format";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import EditAnswers from "views/dialog/EditAnswers";
import EditQuestion from "views/dialog/EditQuestion";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const useStyles2 = makeStyles({
  tableRow: { "&:hover": { backgroundColor: "rgb(237, 247, 237)!important" } },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TestsDone(props) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [idRow, setIdRow] = useState("");
  const [openEnableAnswer, setOpenEnableAnswer] = useState(false);
  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [statusTest, setStatusTest] = useState(1);
  const [returnLogin, setReturnLogin] = React.useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Preguntas",
        field: "statement",
        width: "70%",
        render: (rowData) => {
          return <Fragment>{rowData.statement}</Fragment>;
        },
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
      },
      {
        title: "Tipo Pregunta",
        field: "questiontype.name",
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
      },
      {
        title: "Registro",
        render: (rowData) =>
          format(new Date(rowData.createdAt), "dd-MM-yyyy kk:mm:ss"),
        width: "20%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
      },
      {
        title: "---",
        field: "id",
        render: (rowData) => (
          <Button
            className={classes.buttonSubmit4}
            startIcon={<Search />}
            onClick={() => OpenAnswer(rowData)}
          >
            Respuesta
          </Button>
        ),
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 10,
        },
        sorting: false,
      },
    ],
  });

  useEffect(() => {
    if (props.refresh) {
      TableData();
      props.callbackRefresh();
    } else {
      TableData();
    }
  }, [props.refresh]);

  const TableData = () => {
    setError(false);

    axios
      .get(`${urlServices}tests/${props.id.id}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        setRows(response.data.question);
        setStatusTest(response.data.status);
        setLoader(false);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([]);
          setLoader(false);
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else {
          setRows([]);
          setError(true);
          console.log(e);
        }
      });
  };

  const OpenAnswer = (data) => {
    setIdRow(data);
    setOpenEnableAnswer(true);
  };

  const handleCloseEnableAnswer = () => {
    setOpenEnableAnswer(false);
  };

  const handleCloseEditQuestion = () => {
    setOpenEditQuestion(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info">
                    <div className={classes.cardTitleWhite}>
                      Lista de preguntas registradas
                    </div>
                  </CardHeader>
                  <CardBody>
                    {error ? (
                      <Alert severity="error">
                        Error en la consulta con sel servidor.
                      </Alert>
                    ) : (
                      ""
                    )}
                    {loader ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Box sx={{ width: "100%" }}>
                            <Skeleton
                              width="40%"
                              height={40}
                              style={{ float: "right" }}
                            />
                            <Skeleton width="100%" height={300} />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Fragment>
                        <MaterialTable
                          title=""
                          columns={state.columns}
                          style={{
                            boxShadow: "unset",
                            border: "1px solid rgb(241, 241, 241)",
                          }}
                          data={rows}
                          localization={{
                            toolbar: {
                              // nRowsSelected: '{0} row(s) selected',
                              searchTooltip: "Filtrar",
                              searchPlaceholder: "Buscar",
                            },
                            pagination: {
                              labelRowsSelect: "Filas",
                              labelDisplayedRows: " {from}-{to} de {count}",
                              firstTooltip: "Primera página",
                              previousTooltip: "Previo",
                              nextTooltip: "Siguiente",
                              lastTooltip: "Ultima página",
                            },
                            header: {
                              actions: "",
                            },
                            body: {
                              emptyDataSourceMessage: "No hay preguntas.",
                              filterRow: {
                                filterTooltip: "Filtro",
                              },
                            },
                          }}
                          actions={[
                            (rowData1) => ({
                              icon: () => <Edit />,
                              tooltip: "Editar",
                              onClick: (event, rowData) => {
                                setOpenEditQuestion(true);
                                setIdRow(rowData);
                              },
                              hidden:
                                statusTest === 1 || statusTest === 0
                                  ? true
                                  : false,
                            }),
                          ]}
                          icons={tableIcons}
                          options={{
                            search: true,
                            padding: 1,
                            filtering: false,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            headerStyle: {
                              background: "#F2F2F2",
                              padding: "1%",
                              fontWeight: "bold",
                              textAlign: "center",
                            },
                            cellStyle: { border: "1px solid #DDDDDD" },
                          }}
                          components={{
                            Row: (props) => (
                              <MTableBodyRow
                                className={classes2.tableRow}
                                {...props}
                              />
                            ),
                          }}
                        />
                      </Fragment>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
              {openEnableAnswer ? (
                <EditAnswers
                  open={openEnableAnswer}
                  exit={handleCloseEnableAnswer}
                  id={idRow}
                />
              ) : (
                ""
              )}
              {openEditQuestion ? (
                <EditQuestion
                  open={openEditQuestion}
                  exit={handleCloseEditQuestion}
                  id={idRow}
                  callBackRefresh={TableData}
                />
              ) : (
                ""
              )}
            </GridContainer>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
