import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Alert from '@material-ui/lab/Alert'
import format from 'date-fns/format'
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

//import EnableSinResults from 'views/dialog/EnableSinResults'
import EnableResults from 'views/dialog/EnableResultsApplicant'
import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})
const useStyles1 = makeStyles(cardBodyStyle)

export default function TestsDone(props) {
  const classes = useStyles()
  const classes1 = useStyles1()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [idRow, setIdRow] = useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Prueba',
        field: 'test.name',
        width: '80%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Cargo',
        field: 'test.testjob[0].name',
        width: '80%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Fecha finalizada',
        render: (rowData) => format(new Date(rowData.endDate), 'dd-MM-yyyy'),
        width: '20%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
          textAlign: 'center',
        },
      },
      {
        title: '---',
        render: (rowData) => {
          return (
            rowData.test.showApplicantreport && (
              <Button
                className={classes1.buttonSubmit6}
                onClick={() => OpenResult(rowData)}
              >
                Reporte
              </Button>
            )
          )
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
        sorting: false,
      },
      /*{
        title: "---",
        render: (rowData) => {
          return (
            <Button
              className={classes1.buttonSubmit6}
              onClick={() => OpenResult(rowData)}
            >
              Ver
            </Button>
          );
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
        sorting: false,
      },*/
    ],
  })

  useEffect(() => {
    if (props.refreshTestDone) {
      RefreshTable()
    }
    axios
      .get(`${urlServices}tests-users/user/${localStorage.getItem('id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response.data.filter((filtered) => filtered.status === 3)

          setRows(result)
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization, props])

  const RefreshTable = () => {
    axios
      .get(`${urlServices}tests-users/user/${localStorage.getItem('id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response.data.filter((filtered) => filtered.status === 3)

          setRows(result)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })

    props.refresh()
  }

  const handleCloseOpenResults = () => {
    setOpenResults(false)
  }

  const OpenResult = (data) => {
    let out = {
      testId: data.test.id,
      testName: data.test.name,
      testTime: data.test.time,
      testScore: data.test.totalScore,
      userId: data.user.id,
      userNames: data.user.name + ' ' + data.user.lastName,
      userEmail: data.user.email,
      userDocument: data.user.documentId,
    }

    setIdRow(out)
    setOpenResults(true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Alert variant="filled" severity="success">
              <b style={{ fontSize: 12, marginTop: 5 }}>PRUEBAS FINALIZADAS</b>
            </Alert>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ''
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton
                      width="40%"
                      height={40}
                      style={{ float: 'right' }}
                    />
                    <Skeleton width="100%" height={300} />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                columns={state.columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={rows}
                localization={{
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay pruebas realizadas.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                actions={
                  [
                    /*{
                    icon: "search",
                    tooltip: "Ver",
                    onClick: (event, rowData) => {
                      setopenView(true);
                      setIdRow(rowData);
                    },
                  },*/
                  ]
                }
                icons={tableIcons}
                options={{
                  search: false,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 20,
                  paging: false,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: 12,
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                  toolbar: false,
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes.tableRow} {...props} />
                  ),
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openResults ? (
        // <EnableSinResults
        //   open={openResults}
        //   exit={handleCloseOpenResults}
        //   id={idRow}
        // />
        <EnableResults
          open={openResults}
          exit={handleCloseOpenResults}
          id={idRow}
        />
      ) : (
        ''
      )}
    </GridContainer>
  )
}
