import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import { CheckBox, Cancel, Label } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import {
  cardBodyStyle,
  GreenCheckboxSelected,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'
import { FormLabel } from '@material-ui/core'

const useStyles = makeStyles(cardBodyStyle)

const GreenCheckbox = withStyles(GreenCheckboxSelected)((props) => (
  <Checkbox color="default" {...props} />
))

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '10px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails)

export default function SelectTestPsychometrics(props) {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [selectedFactor, setSelectedFactor] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [selectedRowFactor, setSelectedRowFactor] = useState([])
  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [expanded, setExpanded] = useState('')
  const [checked, setChecked] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)
  let nDisabled = 0

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    axios
      .get(
        `${urlServices}tests-tpm/account/${localStorage.getItem(
          'account_id'
        )}/job/${props.id.id}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then(
        (response) => {
          setRows(response.data)
          setLoader(false)
        },
        (error) => {
          setError(true)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [])

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id)
    let newSelected = []
    let selectedRowData = [...selectedRow]
    let rowData = [...rows]

    if (selectedIndex === -1) {
      //console.log("entro 1");
      selectedRowData.push({
        id: row.id,
        time: 0,
        jobId: props.id.id,
        testId: row.id,
        testLevelId: null,
      })
      newSelected = newSelected.concat(selected, row.id)
    } else if (selectedIndex === 0) {
      selectedRowData.forEach((items, i) => {
        if (row.id === items.id) {
          selectedRowData.splice(i, 1)
          setSelectedRow(selectedRowData)
        }
      })

      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2, i2) => {
          if (row.id === items2.id) {
            items2.timeselected = ''
          }
        })
      })

      setRows(rowData)

      //console.log("entro 2");
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      selectedRowData.forEach((items, i) => {
        if (row.id === items.id) {
          selectedRowData.splice(i, 1)
          setSelectedRow(selectedRowData)
        }
      })

      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2, i2) => {
          if (row.id === items2.id) {
            items2.timeselected = ''
          }
        })
      })

      setRows(rowData)
      //console.log("entro 3");
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      // console.log("entro 4");
      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2, i2) => {
          if (row.id === items2.id) {
            items2.timeselected = ''
          }
        })
      })

      setRows(rowData)

      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    handleChangePanel(row.id, event.target.checked)
    setSelectedRow(selectedRowData)
    setSelected(newSelected)
  }

  // const handleChangeTime = (e, row) => {
  //   let selectedRowData = [...selectedRow]
  //   let rowData = [...rows]

  //   rowData.map((items, i) => {
  //     items.teststpm.map((items2, i2) => {
  //       if (row.id === items2.id) {
  //         items2.timeselected = parseFloat(e.target.value)
  //       }
  //     })
  //   })

  //   selectedRowData.map((items, i) => {
  //     if (row.id === items.id) {
  //       items.time = parseFloat(e.target.value)
  //     }
  //   })

  //   setRows(rowData)
  //   setSelectedRow(selectedRowData)
  // }

  const handleRadioChange = (event, row) => {
    let rowData = [...rows]
    let selectedRowData = [...selectedRow]

    if (selectedRowData.length > 0) {
      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2) => {
          if (row.id === items2.id) {
            items2.radioselected = parseFloat(event.target.value)
          }
        })
      })

      selectedRowData.forEach((items, i) => {
        if (row.id === items.id) {
          items.testLevelId = parseFloat(event.target.value)
        }
      })

      setRows(rowData)
      setSelectedRow(selectedRowData)
    } else {
      setMensaje('Debe seleccionar una prueba y agregar su tiempo')
      setOpenAlert(true)
      setSuccess('error')
    }
  }

  const handleClickFactor = (event, row) => {
    let rowData = [...rows]
    const selectedIndex = selectedFactor.indexOf(row.id)
    let newSelected = []
    let selectedRowData = [...selectedRowFactor]

    if (selectedIndex === -1) {
      // console.log("entro 1");
      selectedRowData.push({
        id: row.factor.id,
        name: row.factor.name,
        score: '',
        factorId: row.factor.id,
        jobId: props.id.id,
        testId: row.testId,
      })
      newSelected = newSelected.concat(selectedFactor, row.id)
    } else if (selectedIndex === 0) {
      selectedRowData.forEach((items, i) => {
        if (row.factor.id === items.id) {
          selectedRowData.splice(i, 1)
          setSelectedRowFactor(selectedRowData)
        }
      })

      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2, i2) => {
          items2.testsfactorstpm.forEach((items3, i3) => {
            if (row.factor.id === items3.factor.id) {
              items3.factor.scoreselected = ''
              items3.factor.nameNew = row.factor.name
            }
          })
        })
      })

      setRows(rowData)
      //console.log("entro 2");
      newSelected = newSelected.concat(selectedFactor.slice(1))
    } else if (selectedIndex === selectedFactor.length - 1) {
      selectedRowData.forEach((items, i) => {
        if (row.factor.id === items.id) {
          selectedRowData.splice(i, 1)
          setSelectedRowFactor(selectedRowData)
        }
      })

      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2, i2) => {
          items2.testsfactorstpm.forEach((items3, i3) => {
            if (row.factor.id === items3.factor.id) {
              items3.factor.scoreselected = ''
              items3.factor.nameNew = row.factor.name
            }
          })
        })
      })

      setRows(rowData)
      // console.log("entro 3");
      newSelected = newSelected.concat(selectedFactor.slice(0, -1))
    } else if (selectedIndex > 0) {
      // console.log("entro 4");
      rowData.forEach((items, i) => {
        items.teststpm.forEach((items2, i2) => {
          items2.testsfactorstpm.forEach((items3, i3) => {
            if (row.factor.id === items3.factor.id) {
              items3.factor.scoreselected = ''
              items3.factor.nameNew = row.factor.name
            }
          })
        })
      })

      setRows(rowData)

      newSelected = newSelected.concat(
        selectedFactor.slice(0, selectedIndex),
        selectedFactor.slice(selectedIndex + 1)
      )
    }

    setSelectedRowFactor(selectedRowData)
    setSelectedFactor(newSelected)
  }

  const handleChangeCompetencia = (e, row) => {
    let rowData = [...rows]
    let selectedRowData = [...selectedRowFactor]

    rowData.forEach((items) => {
      items.teststpm.forEach((items2) => {
        items2.testsfactorstpm.forEach((items3) => {
          if (row.testId === items2.id && row.factor.id === items3.factor.id) {
            items3.factor.nameNew = e.target.value
          }
        })
      })
    })

    selectedRowData.forEach((items) => {
      if (row.factor.id === items.id && row.testId === items.testId) {
        items.name = e.target.value
      }
    })

    setRows(rowData)
    setSelectedRowFactor(selectedRowData)
  }

  const handleChangeScore = (e, row) => {
    let rowData = [...rows]
    let selectedRowData = [...selectedRowFactor]

    let result = selectedRow.filter((filtered) => filtered.id === row.testId)

    if (result.length > 0) {
      rowData.forEach((items) => {
        items.teststpm.forEach((items2) => {
          items2.testsfactorstpm.forEach((items3) => {
            if (
              row.testId === items2.id &&
              row.factor.id === items3.factor.id
            ) {
              items3.factor.scoreselected = parseFloat(e.target.value)
            }
          })
        })
      })

      selectedRowData.forEach((items) => {
        if (row.factor.id === items.id && row.testId === items.testId) {
          items.score = parseFloat(e.target.value)
        }
      })

      setRows(rowData)
      setSelectedRowFactor(selectedRowData)
    } else {
      rowData.forEach((items) => {
        items.teststpm.forEach((items2) => {
          items2.testsfactorstpm.forEach((items3) => {
            if (
              row.testId === items2.id &&
              row.factor.id === items3.factor.id
            ) {
              items3.factor.scoreselected = ''
            }
          })
        })
      })

      selectedRowData.forEach((items) => {
        if (row.factor.id === items.id && row.testId === items.testId) {
          items.score = ''
        }
      })

      setRows(rowData)
      setSelectedRowFactor(selectedRowData)

      setMensaje('Debe seleccionar la prueba y luego agregar los factores')
      setOpenAlert(true)
      setSuccess('error')
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1
  const isSelectedFactor = (id) => selectedFactor.indexOf(id) !== -1

  const handleSave = (pro) => (event) => {
    /*var different = [],
      deleteValue = [];*/
    setDisabledButton(true)
    let out = 0

    selectedRowFactor.forEach((itemsTotal) => {
      // out += parseFloat(itemsTotal.score.replace(/,/g, "."));
      out += parseFloat(itemsTotal.score)
    })

    if (out === parseFloat(props.id.totalScore)) {
      CreateData(selectedRow, selectedRowFactor)

      setMensaje('Registro guardado con éxito.')
      setOpenAlert(true)
      setSuccess('success')
    } else {
      setMensaje('La sumatoria de los pesos no es igual al puntaje total')
      setOpenAlert(true)
      setSuccess('error')
      setDisabledButton(false)
    }

    /*for (var i0 = 0; i0 < selected.length; i0++) {
      var found0 = false;

      for (var j0 = 0; j0 < selectedDifferent.length; j0++) {
        // j < is missed;
        if (selected[i0] === selectedDifferent[j0]) {
          found0 = true;
          break;
        }
      }
      if (found0 === false) {
        different.push(selected[i0]);
      }
    }

    for (var i = 0; i < selectedDifferent.length; i++) {
      var found = false;

      for (var j = 0; j < selected.length; j++) {
        // j < is missed;
        if (selectedDifferent[i] === selected[j]) {
          found = true;
          break;
        }
      }
      if (found === false) {
        deleteValue.push(selectedDifferent[i]);
      }
    }

    if (different.length > 0) {
      let result = [];

      different.map((row) => {
        result = idProfileResources.filter(
          (filtered) => filtered.resourceId === row
        );

        if (result.length === 0) {
          CreateData(row);
        } else {
          for (var i = 0; i < idProfileResources.length; i++) {
            if (idProfileResources[i].resourceId === row) {
              UpdateData(idProfileResources[i].id);
            }
          }
        }
      });
    } 
    
    if (deleteValue.length > 0) {
      let out = [];

      for (var i = 0; i < selectedIdProfile.length; i++) {
        for (var j = 0; j < deleteValue.length; j++) {
          // j < is missed;
          if (selectedIdProfile[i].resourceId === deleteValue[j]) {
            out.push(selectedIdProfile[i]);
          }
        }
      }

      DeleteData(out);
    }
    setMensaje("Registro guardado con exito.");
    setOpenAlert(true);
    setSuccess("success");*/
  }

  const CreateData = (row1, row2) => {
    let n = 0,
      nrows = row1.length

    row1.forEach((items) => {
      const dataValue = {
        time: items.time === '' ? 0 : items.time,
        testLevelId: items.testLevelId,
        testId: items.testId,
        jobId: items.jobId,
      }

      axios
        .post(`${urlServices}jobs-tests-tpm`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {}
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
      n++
    })

    if (n === nrows) {
      CreateDataFactor(row2)
    }
  }

  const CreateDataFactor = (row) => {
    row.forEach((items) => {
      const dataValue = {
        name: items.name,
        score: items.score,
        factorId: items.factorId,
        testId: items.testId,
        jobId: items.jobId,
      }

      axios
        .post(`${urlServices}jobs-factors-tpm`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {}
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleChangePanel = (newExpanded, checked) => {
    setExpanded(newExpanded)
    setChecked(checked)
  }

  // Return classes based on whether item is checked
  var isChecked = (item) => (selectedFactor.includes(item) ? false : true)

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      onClose={handleCloseDialog(true)}
      keepMounted
      scroll="paper"
      // style={{ zIndex: 100 }}
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <CheckBox className={classes.iconWhite} /> Selección de pruebas
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              {error ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={12}>
                <Alert severity="warning" style={{ fontSize: 12 }}>
                  Es <b>IMPORTANTE</b> seleccionar una prueba antes de
                  seleccionar un nivel o factor.
                </Alert>
              </Grid>
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton width="100%" height={150} />
                  </Box>
                </Grid>
              ) : (
                <Fragment>
                  {rows.map((row1, i1) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ margin: '10px 6px' }}
                        key={i1}
                      >
                        <Paper className={classes.paper}>
                          <Accordion
                            square
                            expanded={true}
                            //onChange={handleChangePanel(row1.id)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <b>{row1.name}</b>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'block' }}>
                              {row1.teststpm
                                .sort(function (a, b) {
                                  if (a.order > b.order) {
                                    return 1
                                  }
                                  if (a.order < b.order) {
                                    return -1
                                  }
                                  // a must be equal to b
                                  return 0
                                })
                                .map((row, index) => {
                                  const labelId = `enhanced-table-checkbox-${index}`
                                  let isItemSelected = isSelected(row.id)
                                  if (row.jobsteststpm.length > 0) {
                                    nDisabled++
                                  }
                                  return (
                                    <Fragment key={index}>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Accordion
                                          square
                                          expanded={expanded === row.id}
                                          //onChange={handleChangePanel(row.id)}
                                          style={{
                                            border: 'none',
                                            padding: '0 0 8px 0',
                                          }}
                                        >
                                          <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            style={{
                                              backgroundColor: 'antiquewhite',
                                            }}
                                          >
                                            <FormControlLabel
                                              control={
                                                <GreenCheckbox
                                                  onClick={(event) =>
                                                    handleClick(event, row)
                                                  }
                                                  checked={
                                                    row.jobsteststpm.length > 0
                                                      ? true
                                                      : isItemSelected
                                                  }
                                                  inputProps={{
                                                    'aria-labelledby': labelId,
                                                  }}
                                                />
                                              }
                                              label={row.name}
                                              style={{ color: '#000' }}
                                            />
                                          </AccordionSummary>
                                          <AccordionDetails
                                            style={{ padding: '0' }}
                                          >
                                            <TableContainer>
                                              <Table
                                                aria-labelledby="tableTitle"
                                                size="small"
                                                aria-label="enhanced table"
                                              >
                                                <TableBody>
                                                  {/* <TableRow
                                                    style={{
                                                      backgroundColor:
                                                        'antiquewhite',
                                                      padding: '0 12px 0 16px',
                                                      color:
                                                        'rgba(0, 0, 0, 0.87)',
                                                      borderBottom:
                                                        '1px solid rgba(224, 224, 224, 1)',
                                                    }}
                                                    key={row.id}
                                                  >
                                                    <TableCell
                                                      style={{
                                                        width: 90,
                                                      }}
                                                      colSpan={3}
                                                    >
                                                       <NumberFormat
                                                  customInput={TextField}
                                                  name="time"
                                                  value={
                                                    row.jobsteststpm.length > 0
                                                      ? row.jobsteststpm[0].time
                                                      : row.timeselected
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeTime(e, row)
                                                  }
                                                  allowNegative={false}
                                                  inputProps={{
                                                    style: {
                                                      textAlign: 'center',
                                                      fontSize: 12,
                                                      background: 'white',
                                                      fontWeight: 'bold',
                                                    },
                                                  }}
                                                  label="Tiempo"
                                                  fullWidth
                                                  autoComplete="off"
                                                  variant="outlined"
                                                  margin="dense"
                                                  placeholder="1"
                                                /> 
                                                    </TableCell>
                                                  </TableRow> */}
                                                  {row.testslevelstpm.length >
                                                  0 ? (
                                                    <Fragment>
                                                      <TableRow
                                                        style={{
                                                          backgroundColor:
                                                            'rgb(242, 242, 242)',
                                                          padding:
                                                            '0 12px 0 16px',
                                                          color:
                                                            'rgba(0, 0, 0, 0.87)',
                                                          borderBottom:
                                                            '1px solid rgba(224, 224, 224, 1)',
                                                        }}
                                                      >
                                                        <TableCell
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          colSpan={3}
                                                        >
                                                          <b>
                                                            Seleccionar nivel:
                                                          </b>
                                                        </TableCell>
                                                      </TableRow>
                                                      <TableRow>
                                                        <TableCell>
                                                          <RadioGroup
                                                            value={
                                                              row.jobsteststpm
                                                                .length > 0
                                                                ? row
                                                                    .jobsteststpm[0]
                                                                    .testLevelId
                                                                : row.radioselected ===
                                                                  undefined
                                                                ? ''
                                                                : row.radioselected
                                                            }
                                                            onChange={(e) =>
                                                              handleRadioChange(
                                                                e,
                                                                row
                                                              )
                                                            }
                                                            row
                                                          >
                                                            {row.testslevelstpm.map(
                                                              (row2, index) => {
                                                                return (
                                                                  <FormControlLabel
                                                                    value={
                                                                      row2.id
                                                                    }
                                                                    control={
                                                                      <GreenRadio
                                                                        key={
                                                                          index
                                                                        }
                                                                      />
                                                                    }
                                                                    label={
                                                                      row2.name
                                                                    }
                                                                    name={
                                                                      row2.name
                                                                    }
                                                                    labelPlacement="start"
                                                                    style={{
                                                                      color:
                                                                        'dimgray',
                                                                      fontSize: 10,
                                                                    }}
                                                                    key={index}
                                                                  />
                                                                )
                                                              }
                                                            )}
                                                          </RadioGroup>
                                                        </TableCell>
                                                      </TableRow>
                                                    </Fragment>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  <TableRow key={index}>
                                                    <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      colSpan={3}
                                                    >
                                                      <TableContainer
                                                        key={index}
                                                      >
                                                        <Table
                                                          aria-labelledby="tableTitle"
                                                          size="small"
                                                          aria-label="enhanced table"
                                                        >
                                                          <TableBody>
                                                            <TableRow
                                                              style={{
                                                                backgroundColor:
                                                                  'rgb(242, 242, 242)',
                                                                padding:
                                                                  '0 12px 0 16px',
                                                                color:
                                                                  'rgba(0, 0, 0, 0.87)',
                                                                borderBottom:
                                                                  '1px solid rgba(224, 224, 224, 1)',
                                                              }}
                                                            >
                                                              <TableCell
                                                                style={{
                                                                  fontSize: 12,
                                                                }}
                                                                colSpan={4}
                                                              >
                                                                <b>
                                                                  Seleccionar
                                                                  factor:
                                                                </b>
                                                              </TableCell>
                                                            </TableRow>
                                                            {row.testsfactorstpm.map(
                                                              (row3, index) => {
                                                                let isItemSelectedFactor =
                                                                  isSelectedFactor(
                                                                    row3.id
                                                                  )

                                                                return (
                                                                  <TableRow
                                                                    hover
                                                                    key={index}
                                                                  >
                                                                    <TableCell
                                                                      component="th"
                                                                      id={
                                                                        labelId
                                                                      }
                                                                      scope="row"
                                                                      padding="none"
                                                                      style={{
                                                                        fontSize: 12,
                                                                      }}
                                                                    >
                                                                      <FormControlLabel
                                                                        control={
                                                                          <GreenCheckbox
                                                                            onClick={(
                                                                              event
                                                                            ) =>
                                                                              handleClickFactor(
                                                                                event,
                                                                                row3
                                                                              )
                                                                            }
                                                                            checked={
                                                                              row3
                                                                                .factor
                                                                                .jobsfactorstpm
                                                                                .length >
                                                                              0
                                                                                ? true
                                                                                : isItemSelectedFactor
                                                                            }
                                                                            inputProps={{
                                                                              'aria-labelledby':
                                                                                labelId,
                                                                            }}
                                                                          />
                                                                        }
                                                                        label={
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                '#000',
                                                                              fontSize: 12,
                                                                            }}
                                                                          >
                                                                            {
                                                                              row3
                                                                                .factor
                                                                                .name
                                                                            }
                                                                          </span>
                                                                        }
                                                                        disabled={
                                                                          nDisabled >
                                                                            0 ||
                                                                          disabledButton
                                                                            ? true
                                                                            : false
                                                                        }
                                                                      />
                                                                    </TableCell>
                                                                    <TableCell
                                                                      component="th"
                                                                      id={
                                                                        labelId
                                                                      }
                                                                      scope="row"
                                                                      padding="none"
                                                                      style={{
                                                                        width: 130,
                                                                        padding:
                                                                          '0 20px 0 10px',
                                                                      }}
                                                                    >
                                                                      <TextField
                                                                        name="name"
                                                                        value={
                                                                          row3
                                                                            .factor
                                                                            .nameNew ===
                                                                          undefined
                                                                            ? row3
                                                                                .factor
                                                                                .jobsfactorstpm
                                                                                .length >
                                                                              0
                                                                              ? row3
                                                                                  .factor
                                                                                  .jobsfactorstpm[0]
                                                                                  .name
                                                                              : row3
                                                                                  .factor
                                                                                  .name
                                                                            : row3
                                                                                .factor
                                                                                .nameNew
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeCompetencia(
                                                                            e,
                                                                            row3
                                                                          )
                                                                        }
                                                                        label="Competencia"
                                                                        margin="dense"
                                                                        autoComplete="off"
                                                                        variant="outlined"
                                                                        inputProps={{
                                                                          style:
                                                                            {
                                                                              fontSize: 12,
                                                                            },
                                                                        }}
                                                                        disabled={isChecked(
                                                                          row3.id
                                                                        )}
                                                                      />
                                                                    </TableCell>
                                                                    <TableCell
                                                                      component="th"
                                                                      id={
                                                                        labelId
                                                                      }
                                                                      scope="row"
                                                                      padding="none"
                                                                      style={{
                                                                        width: 70,
                                                                      }}
                                                                    >
                                                                      <NumberFormat
                                                                        customInput={
                                                                          TextField
                                                                        }
                                                                        name="score"
                                                                        value={
                                                                          row3
                                                                            .factor
                                                                            .jobsfactorstpm
                                                                            .length >
                                                                          0
                                                                            ? row3
                                                                                .factor
                                                                                .jobsfactorstpm[0]
                                                                                .score
                                                                            : row3
                                                                                .factor
                                                                                .scoreselected
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeScore(
                                                                            e,
                                                                            row3
                                                                          )
                                                                        }
                                                                        allowNegative={
                                                                          false
                                                                        }
                                                                        inputProps={{
                                                                          style:
                                                                            {
                                                                              textAlign:
                                                                                'center',
                                                                              fontSize: 12,
                                                                              fontWeight:
                                                                                'bold',
                                                                            },
                                                                        }}
                                                                        decimalSeparator="."
                                                                        label="Peso"
                                                                        fullWidth
                                                                        autoComplete="off"
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                        disabled={isChecked(
                                                                          row3.id
                                                                        )}
                                                                        // placeholder="1 - 100"
                                                                      />
                                                                    </TableCell>
                                                                  </TableRow>
                                                                )
                                                              }
                                                            )}
                                                          </TableBody>
                                                        </Table>
                                                      </TableContainer>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    </Fragment>
                                  )
                                })}
                            </AccordionDetails>
                          </Accordion>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Fragment>
              )}
            </Grid>
          </CardBody>
        </Card>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleClose}
        >
          <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleClose}
              severity={success === 'error' ? 'error' : 'success'}
              elevation={6}
              variant="filled"
            >
              {mensaje}
            </Alert>
          </Slide>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={10} sm={10} style={{ paddingLeft: 170 }}>
            <Button
              type="submit"
              className={classes.buttonSubmit}
              onClick={handleSave()}
              disabled={nDisabled > 0 || disabledButton ? true : false}
            >
              Aceptar
            </Button>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Button onClick={handleCloseDialog(true)}>
              <Cancel />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
