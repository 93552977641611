import React, { createContext, useState } from 'react'

const UrlServicesContext = createContext()
const { Provider, Consumer } = UrlServicesContext

function UrlServicesProvider({ children }) {
  /* ### API ### */
  const [urlServices] = useState(process.env.REACT_APP_URL_SERVICES)

  /* ### PROFILE ### */
  const [urlLocalMediaProfile] = useState(
    process.env.REACT_APP_URL_LOCAL_MEDIA_PROFILE
  )

  /* ### LOAD SERVICES KEYAPLICATION */
  const [keyAplication] = useState(process.env.REACT_APP_APPLICATION_ID)

  return (
    <Provider
      value={{
        urlServices,
        urlLocalMediaProfile,
        keyAplication,
        // urlBase,
      }}
    >
      {children}
    </Provider>
  )
}

export {
  UrlServicesProvider,
  Consumer as urlServicesConsumer,
  UrlServicesContext,
}
