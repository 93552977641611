import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import { Cancel } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function EditAnswers(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [returnLogin, setReturnLogin] = useState(false)
  const [loader, setLoader] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let data = props.id

  const [values, setValues] = useState({
    answers: [
      {
        statement: '',
        rightAnswer: '',
        id: '',
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(`${urlServices}answers`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let out = []
          let result = response.data.rows.filter(
            (filtered) => Number(filtered.questionId) === data.id
          )

          if (result.length > 0) {
            result.map((row1) => {
              out.push({
                statement: row1.statement.toString(),
                rightAnswer: row1.rightAnswer.toString(),
                id: row1.id,
              })
            })
          }

          setValues({
            ...values,
            answers: out,
          })
        }

        setLoader(true)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setError(true)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setError('')

    if (values.answers.length === 0) {
      setMensaje('Seleccione una opción.')
      setOpenAlert(true)
      setError('error')
    } else {
      if (data.questiontypeId === 1) {
        const dataAnswers = {
          statement:
            values.answers[0].rightAnswer === 'true' ? 'Verdadero' : 'Falso',
          rightAnswer: values.answers[0].rightAnswer,
        }

        UpdateAnswer(values.answers[0].id, dataAnswers)
      } else if (data.questiontypeId === 2) {
        values.answers.map((row1) => {
          const dataAnswers = {
            statement: row1.statement,
            rightAnswer: row1.rightAnswer,
          }

          UpdateAnswer(row1.id, dataAnswers)
        })
      }

      setOpenAlert(true)
      setMensaje('Respuesta actualizada con éxito.')
      setError('success')
    }
  }

  const UpdateAnswer = async (id, dataValue) => {
    await axios
      .put(`${urlServices}answers/${id}`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleRadioChange = (event, row, n) => {
    if (event.target.value !== undefined) {
      if (n === 0) {
        /*setValues({
          ...values,
          answers: [
            {
              statement: event.target.value,
              rightAnswer: event.target.value,
              id: row,
            },
          ],
        });*/
        values.answers.map((row1, i1) => {
          if (row === row1.id) {
            row1.rightAnswer = event.target.value
          }
        })

        setValues({ ...values, answers: values.answers })
      } else {
        values.answers.map((row1, i1) => {
          if (row === row1.id) {
            row1.rightAnswer = event.target.checked.toString()
          }
        })

        setValues({ ...values, answers: values.answers })
      }
    }
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        // style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Respuesta</div>
                <div className={classes.lineBottom}></div>
              </Grid>

              {loader ? (
                <Fragment>
                  {data.questiontypeId === 1 ? (
                    <Grid item xs={12} sm={12}>
                      <Fragment>
                        {/* <RadioGroup
                  aria-label="answers"
                  name="answers"
                  value={values.answers[0].rightAnswer}
                >
                  <FormControlLabel
                    value="true"
                    control={<GreenRadio />}
                    label="Verdadero"
                    style={{ color: "dimgray" }}
                    onClick={(event) => {
                      handleRadioChange(event, values.answers[0].id, 0);
                    }}
                  />
                  <FormControlLabel
                    value="false"
                    control={<GreenRadio />}
                    label="Falso"
                    style={{ color: "dimgray" }}
                    onClick={(event) => {
                      handleRadioChange(event, values.answers[0].id, 0);
                    }}
                  />
                </RadioGroup>*/}
                        <RadioGroup
                          aria-label="answers"
                          name="answers"
                          value={true}
                        >
                          {values.answers.map((row) => {
                            return (
                              <FormControlLabel
                                value={
                                  row.rightAnswer === 'true' ? true : false
                                }
                                control={<GreenRadio />}
                                label={row.statement}
                                style={{ color: 'dimgray' }}
                                /*onClick={(event) => {
                          handleRadioChange(event, row, 0);
                        }}*/
                              />
                            )
                          })}
                        </RadioGroup>
                      </Fragment>
                    </Grid>
                  ) : data.questiontypeId === 2 ||
                    data.questiontypeId === 3 ||
                    data.questiontypeId === 4 ? (
                    <Fragment>
                      {values.answers.map((row1) => {
                        return (
                          <Grid item xs={6} sm={6}>
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={
                                    row1.rightAnswer === 'true' ? true : false
                                  }
                                  onClick={(event) => {
                                    handleRadioChange(event, row1.id, 1)
                                  }}
                                  name={row1.statement}
                                />
                              }
                              label={row1.statement}
                              style={{ color: 'dimgray' }}
                            />
                          </Grid>
                        )
                      })}
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Fragment>
              ) : (
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      alt="loader"
                      src={img}
                      style={{ width: 100, margin: '7px 0' }}
                    />
                  </center>
                </Grid>
              )}

              {/*<Grid item xs={12} sm={12}>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                  >
                    Guardar
                  </Button>
                </center>
              </Grid>*/}
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
