import React, { useContext, useState, useEffect, Fragment } from 'react'

import { UrlServicesContext } from 'components/UrlServicesContext'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from '@react-pdf/renderer'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import font from 'assets/font/Roboto-Bold.ttf'
import format from 'date-fns/format'

import unchecked from 'assets/img/unchecked-checkbox.png'
import checkbox from 'assets/img/checkbox.jpeg'
import negativecheckbox from 'assets/img/negativecheckbox.png'

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    padding: 25,
  },
  table: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    marginTop: 5,
  },
  tableHeader: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableDinamic: {
    display: 'table',
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    marginTop: 25,
  },
  tableRow: {
    //margin: 'auto',
    flexDirection: 'row',
    width: '100%',
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
  },
  tableRowBottom: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 200,
  },
  tableCol1HeaderAll: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '100%',
  },
  colHeader: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
    width: 150,
  },
  tableCol1HeaderDinamic: {
    width: '100%',
    borderWidth: 0,
    padding: 10,
    backgroundColor: 'rgb(242, 242, 242)',
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol1: {
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamic: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamicCenter: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
    width: '100%',
    marginBottom: '-10px',
    marginTop: 5,
  },
  tableCellHeaderDinamicRight: {
    fontSize: 8,
    textAlign: 'right',
    padding: 5,
  },
  tableCellHeaderDinamicResult: {
    padding: 5,
    width: '100%',
    textAlign: 'justify',
  },
  tableResult: {
    marginBottom: 10,
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: '#000',
    padding: 1,
    borderRadius: 10,
    width: 100,
  },
  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: 'Roboto-Bold',
  },
})

Font.register({
  family: 'Roboto-Bold',
  src: font,
})

function PrintPDFResultsApplicantsTest(props) {
  const [score, setScore] = useState(0)
  const [rowsQuestion, setRowsQuestion] = useState([])
  const [openBackdrop, setoOpenBackdrop] = useState(true)
  const [totalScore, setTotalScore] = useState(0)
  const [cargo, setCargo] = React.useState('')
  const [testName, setTestName] = React.useState('')
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [createdAt, setCreatedAt] = React.useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let params = queryString.parse(props.location.search)

  useEffect(() => {
    axios
      .get(`${urlServices}tests-results/${params.test}/${params.userID}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let scoreTotal = 0
          console.log(response.data)
          response.data.question.map((items) => {
            items.testresults.map((items2) => {
              scoreTotal += parseFloat(items2.score)
            })
          })

          setRowsQuestion(response.data.question)
          setTotalScore(response.data.totalScore)
          setScore(scoreTotal.toFixed(2))
          setCargo(response.data.testjob[0].name)
          setTestName(response.data.name)
          setCreatedAt(response.data.createdAt)
          setTotalQuestions(response.data.totalQuestions)

          setoOpenBackdrop(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [])

  let select = ''

  if (returnLogin) {
    return <Redirect to="/" />
  }

  if (openBackdrop) {
    return (
      <Fragment>
        <Backdrop
          style={{ zIndex: '9999', color: '#FFFFFF' }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    )
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4" wrap>
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.titlePage}> Resultados de la prueba</Text>
              <Text style={styles.lineBottom}></Text>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Candidat@:
                  <Text style={styles.tableCell}> {' ' + params.userName}</Text>
                </Text>
              </View>
              <View style={{ width: 150 }}></View>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Puntaje total:
                  <Text style={styles.tableCell}>
                    {' ' + score + ' / ' + totalScore}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Correo:
                  <Text style={styles.tableCell}>{' ' + params.userEmail}</Text>
                </Text>
              </View>
              <View style={{ width: 150 }}></View>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Identificación:
                  <Text style={styles.tableCell}>
                    {' ' + params.userDocument}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Prueba:
                  <Text style={styles.tableCell}>{' ' + testName}</Text>
                </Text>
              </View>
              <View style={{ width: 150 }}></View>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Cargo:
                  <Text style={styles.tableCell}>{' ' + cargo}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Fecha:
                  <Text style={styles.tableCell}>
                    {' ' + format(new Date(createdAt), 'dd-MM-yyyy')}
                  </Text>
                </Text>
              </View>
              <View style={{ width: 150 }}></View>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Preguntas:&nbsp;
                  <Text style={styles.tableCell}>{' ' + totalQuestions}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Sin respuesta:&nbsp;
                  <Text style={styles.tableCell}>
                    {' ' + totalQuestions - rowsQuestion.length}
                  </Text>
                </Text>
              </View>
              <View style={{ width: 150 }}></View>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellHeaderDinamicCenter}>
                PREGUNTAS Y RESPUESTAS
              </Text>
            </View>

            <View style={styles.tableDinamic}>
              {rowsQuestion.map((row, n) => {
                let scoreResut = 0
                row.testresults.map((row11) => {
                  scoreResut += parseFloat(row11.score)
                })

                return (
                  <View key={n}>
                    <View style={styles.tableCol1HeaderDinamic}>
                      <Text style={styles.tableCellHeaderDinamic}>
                        {n + 1 + '-. '} {row.statement}
                      </Text>
                    </View>
                    <View style={styles.tableCellHeaderDinamicRight}>
                      <Text>
                        Puntaje:{' '}
                        {scoreResut !== 0 ? (
                          <View>
                            {' '}
                            <Text style={{ fontSize: 12, color: 'green' }}>
                              {' '}
                              {scoreResut.toFixed(2)}{' '}
                            </Text>
                          </View>
                        ) : (
                          <View>
                            {' '}
                            <Text style={{ fontSize: 12, color: 'red' }}>
                              {scoreResut.toFixed(2)}{' '}
                            </Text>
                          </View>
                        )}
                      </Text>
                    </View>

                    <View style={[styles.table, styles.tableResult]}>
                      {row.testresults.map((row2) => {
                        if (
                          row2.selectedAnswer === true &&
                          row2.rightAnswer === true
                        ) {
                          select = checkbox
                        } else if (row2.selectedAnswer === true) {
                          select = negativecheckbox
                        } else {
                          select = unchecked
                        }

                        return (
                          <View>
                            <View style={styles.tableRow}>
                              <Text
                                style={[
                                  styles.tableCellHeaderDinamic,
                                  styles.tableCellHeaderDinamicResult,
                                ]}
                              >
                                <Image src={select} style={{ width: 15 }} />{' '}
                                {row2.answer.statement}
                              </Text>
                            </View>
                          </View>
                        )
                      })}
                    </View>
                  </View>
                )
              })}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    )
  }
}
export default PrintPDFResultsApplicantsTest
