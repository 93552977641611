import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, AccessTime, ArrowRightAlt } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import format from 'date-fns/format'

import StartTest from 'views/dialog/StartTest'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)
const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(255, 244, 229)!important' } },
})

export default function PendingTestsList(props) {
  const classes = useStyles()
  const classes2 = useStyles2()

  const [idRow, setIdRow] = useState('')
  const [rows, setRows] = useState([])
  const [OpenEnable, setOpenEnable] = useState(false)
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Prueba',
        field: 'test.name',
        width: '40%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
          fontWeight: 'bold',
        },
      },
      {
        title: 'Cargo',
        field: 'test.testjob[0].name',
        width: '40%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
          fontWeight: 'bold',
        },
      },
      {
        title: 'Presentar',
        field: '',
        render: (rowData) => {
          let nameButton =
              rowData.status === 1
                ? 'Iniciar'
                : rowData.status === 2
                ? 'Continuar'
                : '',
            css =
              rowData.status === 1
                ? classes.buttonSubmit6
                : rowData.status === 2
                ? classes.buttonSubmit5
                : '',
            startDateNull = rowData.test.activationTime,
            fechaInicio = startDateNull
              ? new Date(
                  format(
                    new Date(rowData.test.activationStart),
                    'yyyy-MM-dd HH:mm'
                  )
                ).getTime()
              : '',
            //new Date(
            //  format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            //).getTime(),
            fechaFin = startDateNull
              ? new Date(
                  format(
                    new Date(rowData.test.activationEnd),
                    'yyyy-MM-dd HH:mm'
                  )
                ).getTime()
              : '',
            currentDate = new Date(
              format(new Date(), 'yyyy-MM-dd HH:mm')
            ).getTime(),
            dateCounterFinal = startDateNull
              ? currentDate >= fechaInicio && currentDate <= fechaFin
              : false

          return startDateNull ? (
            dateCounterFinal ? (
              <Button
                className={css}
                onClick={() => openEnable(rowData)}
                endIcon={
                  rowData.status === 1 ? (
                    <AccessTime
                      style={{ verticalAlign: 'middle', fontSizes: 8 }}
                    />
                  ) : (
                    <ArrowRightAlt
                      style={{ verticalAlign: 'middle', fontSizes: 8 }}
                    />
                  )
                }
              >
                {nameButton}
              </Button>
            ) : (
              <b>No disponible</b>
            )
          ) : (
            <Button
              className={css}
              onClick={() => openEnable(rowData)}
              endIcon={
                rowData.status === 1 ? (
                  <AccessTime
                    style={{ verticalAlign: 'middle', fontSizes: 8 }}
                  />
                ) : (
                  <ArrowRightAlt
                    style={{ verticalAlign: 'middle', fontSizes: 8 }}
                  />
                )
              }
            >
              {nameButton}
            </Button>
          )
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
        sorting: false,
      },
    ],
  })

  const handleClose = () => {
    setOpenEnable(false)
  }

  const openEnable = (data) => {
    let out = {
      id: data.id,
      testId: data.testId,
      user: data.user,
      status: data.status,
      test: data.test,
      takePhoto: data.test.takePhoto,
    }

    setIdRow(out)
    setOpenEnable(true)
  }

  useEffect(() => {
    axios
      .get(`${urlServices}tests-users/user/${localStorage.getItem('id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response.data.filter(
            (filtered) => filtered.status === 1 || filtered.status === 2
          )
          setRows(result)
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const RefreshTable = () => {
    axios
      .get(`${urlServices}tests-users/user/${localStorage.getItem('id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response.data.filter(
            (filtered) => filtered.status === 1 || filtered.status === 2
          )
          setRows(result)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })

    props.callBackTestDone()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader variant="filled" severity="warning">
            <Alert variant="filled" severity="warning">
              <b style={{ fontSize: 12, marginTop: 5 }}>PRUEBAS PENDIENTES</b>
            </Alert>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ''
            )}
            {loader ? (
              <Grid container spacing={2}>
                <GridItem xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton
                      width="40%"
                      height={40}
                      style={{ float: 'right' }}
                    />
                    <Skeleton width="100%" height={300} />
                  </Box>
                </GridItem>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                columns={state.columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={rows}
                localization={{
                  // toolbar: {
                  //   // nRowsSelected: '{0} row(s) selected',
                  //   searchTooltip: 'Filtrar',
                  //   searchPlaceholder: 'Buscar',
                  // },
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  // header: {
                  //   actions: ' --- ',
                  // },
                  body: {
                    emptyDataSourceMessage: 'No hay pruebas pendientes.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                icons={tableIcons}
                options={{
                  search: false,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  paging: false,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: 12,
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                  toolbar: false,
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      {OpenEnable ? (
        <StartTest
          open={OpenEnable}
          exit={handleClose}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}
    </GridContainer>
  )
}
