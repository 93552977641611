import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, AccessTime, ArrowRightAlt } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import format from 'date-fns/format'

import StartTest from 'views/dialog/StartTestPsychometrics'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)
const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(255, 244, 229)!important' } },
})

export default function PendingTestsListPsychometrics(props) {
  const classes = useStyles()
  const classes2 = useStyles2()
  const tableRef = React.createRef()

  const [idRow, setIdRow] = useState('')
  const [OpenEnable, setOpenEnable] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Prueba',
        field: 'jobtesttpm.test.name',
        width: '45%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Cargo',
        field: 'jobtesttpm.job.name',
        width: '45%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Presentar',
        field: '',
        render: (rowData) => {
          let nameButton =
              rowData.status === 1
                ? 'Iniciar'
                : rowData.status === 2
                ? 'Continuar'
                : '',
            css =
              rowData.status === 1
                ? classes.buttonSubmit6
                : rowData.status === 2
                ? classes.buttonSubmit5
                : '',
            startDateNull = rowData.jobtesttpm.job.activation_time,
            fechaInicio = startDateNull
              ? new Date(
                  format(
                    new Date(rowData.jobtesttpm.job.activation_start),
                    'yyyy-MM-dd HH:mm'
                  )
                ).getTime()
              : '',
            fechaFin = startDateNull
              ? new Date(
                  format(
                    new Date(rowData.jobtesttpm.job.activation_end),
                    'yyyy-MM-dd HH:mm'
                  )
                ).getTime()
              : '',
            currentDate = new Date(
              format(new Date(), 'yyyy-MM-dd HH:mm')
            ).getTime(),
            dateCounterFinal = startDateNull
              ? currentDate >= fechaInicio && currentDate <= fechaFin
              : false

          return startDateNull ? (
            dateCounterFinal ? (
              <Button
                className={css}
                onClick={() => openEnable(rowData)}
                endIcon={
                  rowData.status === 1 ? (
                    <AccessTime
                      style={{ verticalAlign: 'middle', fontSizes: 8 }}
                    />
                  ) : (
                    <ArrowRightAlt
                      style={{ verticalAlign: 'middle', fontSizes: 8 }}
                    />
                  )
                }
              >
                {nameButton}
              </Button>
            ) : (
              <b>No disponible</b>
            )
          ) : (
            <Button
              className={css}
              onClick={() => openEnable(rowData)}
              endIcon={
                rowData.status === 1 ? (
                  <AccessTime
                    style={{ verticalAlign: 'middle', fontSizes: 8 }}
                  />
                ) : (
                  <ArrowRightAlt
                    style={{ verticalAlign: 'middle', fontSizes: 8 }}
                  />
                )
              }
            >
              {nameButton}
            </Button>
          )
        },
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
        sorting: false,
      },
    ],
  })

  const handleClose = () => {
    setOpenEnable(false)
  }

  const openEnable = (data) => {
    let out = {
      id: data.id,
      jobId: data.jobtesttpm.jobId,
      user: data.applicant,
      test: data.jobtesttpm.test,
      status: data.status,
      takePhoto: data.jobtesttpm.job.takePhoto,
    }

    setIdRow(out)
    setOpenEnable(true)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const RefreshTablePsychometrics = () => {
    tableRef.current && tableRef.current.onQueryChange()
    props.callBackTestDonePsychometrics()
  }

  let url = ''

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Alert variant="filled" severity="warning">
              <b style={{ fontSize: 12, marginTop: 5 }}>PRUEBAS PENDIENTES</b>
            </Alert>
          </CardHeader>
          <CardBody>
            <MaterialTable
              title=""
              tableRef={tableRef}
              columns={state.columns}
              style={{
                boxShadow: 'unset',
                border: '1px solid rgb(241, 241, 241)',
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  url = `${urlServices}jobs-users-tpm/user/${localStorage.getItem(
                    'id'
                  )}?limit=50&page=${query.page + 1}`
                  fetch(url, {
                    method: 'get',
                    headers: {
                      application: keyAplication,
                      Authorization: 'Bearer ' + keyAuthorization,
                    },
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        //data: result.data,
                        data:
                          result.data !== undefined
                            ? result.data.filter((obj) => obj.status !== 3)
                            : [],
                        page:
                          result.page !== undefined
                            ? result.data.status !== 3
                              ? result.page - 1
                              : 0
                            : [],
                        totalCount:
                          result.total !== undefined
                            ? result.data.status !== 3
                              ? result.total - result.data.length
                              : 0
                            : [],
                      })
                    })
                })
              }
              localization={{
                toolbar: {
                  // nRowsSelected: '{0} row(s) selected',
                  searchTooltip: 'Filtrar',
                  searchPlaceholder: 'Buscar',
                },
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primera página',
                  previousTooltip: 'Previo',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Ultima página',
                },
                header: {
                  actions: ' --- ',
                },
                body: {
                  emptyDataSourceMessage: 'No hay pruebas pendientes.',
                  filterRow: {
                    filterTooltip: 'Filtro',
                  },
                },
              }}
              icons={tableIcons}
              options={{
                search: false,
                filtering: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                paging: false,
                headerStyle: {
                  background: '#F2F2F2',
                  padding: '1%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 12,
                },
                cellStyle: { border: '1px solid #DDDDDD' },
                toolbar: false,
              }}
              components={{
                Row: (props) => (
                  <MTableBodyRow className={classes2.tableRow} {...props} />
                ),
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {OpenEnable ? (
        <StartTest
          open={OpenEnable}
          exit={handleClose}
          id={idRow}
          callBackRefreshPsychometrics={RefreshTablePsychometrics}
        />
      ) : (
        ''
      )}
    </GridContainer>
  )
}
