import React, { useContext, useState, useEffect, Fragment } from 'react'

import { UrlServicesContext } from 'components/UrlServicesContext'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import font from 'assets/font/Roboto-Bold.ttf'
import queryString from 'query-string'

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    padding: 25,
  },
  table: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    marginTop: 5,
    borderCollapse: 'collapse',
  },
  tableHeader: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderCollapse: 'collapse',
  },
  tableDinamic: {
    display: 'table',
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    marginTop: 25,
    borderCollapse: 'collapse',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    width: '100%',
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol2Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  colHeader: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
    marginTop: '-5px',
    fontSize: 8,
  },
  tableCol1HeaderDinamic: {
    width: '100%',
    borderWidth: 0,
    padding: 8,
    textAlign: 'center',
    backgroundColor: 'rgb(242, 242, 242)',
  },
  tableCol1: {
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamic: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
  },
  tableCell1: {
    fontSize: 8,
  },
  tableCellHeaderDinamic2: {
    fontSize: 10,
    width: 300,
    padding: '5px 0 0 5px',
  },

  tableCellHeader1: {
    fontSize: 8,
    width: 60,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
  },
  tableCellHeader2: {
    fontSize: 8,
    width: 305,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
  },
  tableCellHeader3: {
    fontSize: 8,
    width: 450,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    padding: '5px 0 0 80px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
  },
  tableCellHeader4: {
    fontSize: 8,
    width: 150,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
    textTransform: 'uppercase',
  },
  tableCellHeader44: {
    fontSize: 8,
    width: 80,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
    textTransform: 'uppercase',
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCellData1: {
    padding: 4,
    width: 60,
  },
  tableCellData2: {
    padding: '5px 4px 4px 4px',
    width: 305,
    textTransform: 'uppercase',
  },
  tableCellData3: {
    padding: 4,
    width: 450,
  },
  tableCellData4: {
    padding: '5px 4px 4px 25px',
    width: 150,
  },
  tableCellData44: {
    padding: '5px 4px 4px 15px',
    width: 80,
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: '#000',
    padding: 1,
    borderRadius: 10,
    width: 100,
  },

  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: 'Roboto-Bold',
  },
})

Font.register({
  family: 'Roboto-Bold',
  src: font,
})
// Create Document Component
function PrintPDFReportGeneralJobs(props) {
  const [rows, setRows] = useState([])
  const [openBackdrop, setoOpenBackdrop] = useState(true)
  const [account, setAccount] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let params = queryString.parse(props.location.search)

  useEffect(() => {
    const dataValue = {
      jobId: params.jobs,
      fromDate: params.startDate,
      toDate: params.endDate,
    }

    axios
      .post(`${urlServices}reports/general/factors`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data)
          accountSearch()
          setoOpenBackdrop(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setoOpenBackdrop(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setoOpenBackdrop(false)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const accountSearch = () => {
    axios
      .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAccount(response.data.companyName)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  let n = 1

  if (returnLogin) {
    return <Redirect to="/" />
  }

  if (openBackdrop) {
    return (
      <Fragment>
        <Backdrop
          style={{ zIndex: '9999', color: '#FFFFFF' }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    )
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4" orientation="landscape" wrap>
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.titlePage}> Reporte general por cargo</Text>
              <Text style={styles.lineBottom}></Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Institución/Empresa:
                    <Text style={styles.tableCell}> {' ' + account}</Text>
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Cargo:
                    <Text style={styles.tableCell}>
                      {' ' + rows.jobTpm.name}
                    </Text>
                  </Text>
                </View>
                <View style={{ width: 300 }}></View>
              </View>
              {params.startDate && params.endDate ? (
                <Fragment>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1HeaderLeft}>
                      <Text style={styles.tableCellHeader}>
                        Desde:
                        <Text style={styles.tableCell}>
                          {' ' + params.startDate}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ width: 300 }}></View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1HeaderLeft}>
                      <Text style={styles.tableCellHeader}>
                        Hasta:
                        <Text style={styles.tableCell}>
                          {' ' + params.endDate}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ width: 300 }}></View>
                  </View>
                </Fragment>
              ) : (
                <></>
              )}
            </View>

            <View style={styles.tableDinamic}>
              <View style={styles.tableRowDinamic}>
                <View style={styles.tableCol1HeaderDinamic}>
                  <Text style={styles.tableCellHeaderDinamic}>
                    LISTADO DE CANDIDATOS
                  </Text>
                </View>
              </View>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCellHeader2}>
                    <Text>CANDIDATO</Text>
                  </View>
                  <View style={styles.tableCellHeader4}>
                    <Text>DOCUMENTO</Text>
                  </View>

                  {rows.jobFactor.map((rowFactor) => {
                    return (
                      <View style={styles.tableCellHeader4}>
                        <Text> {rowFactor.name}</Text>
                      </View>
                    )
                  })}
                  <View style={styles.tableCellHeader44}>
                    <Text>TOTAL</Text>
                  </View>
                  <View style={styles.tableCellHeader4}>
                    <Text>EQUIVALENCIA</Text>
                  </View>
                </View>
              </View>

              {rows.jobUser.map((row1, i) => {
                let scoretotal = 0
                return (
                  <View key={i}>
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.colHeader}>
                          <Text style={styles.tableCellData2}>
                            {row1.applicant.name +
                              ' ' +
                              row1.applicant.lastName}
                          </Text>
                        </View>
                        <View style={styles.colHeader}>
                          <Text style={styles.tableCellData4}>
                            {row1.applicant.documentId}
                          </Text>
                        </View>

                        {row1.testsResultsTpm.length > 0 ? (
                          <View
                            style={{ display: 'flex', flexDirection: 'row' }}
                            break
                          >
                            {rows.jobFactor.map((row2) => {
                              scoretotal += parseFloat(
                                row1.testsResultsTpm.find(
                                  (e) => e.factorId == row2.factorId
                                )?.score || 0
                              )
                              return (
                                <View style={styles.colHeader}>
                                  <Text style={styles.tableCellData4}>
                                    {parseFloat(
                                      row1.testsResultsTpm.find(
                                        (e) => e.factorId == row2.factorId
                                      )?.score || 0
                                    ).toFixed(2)}
                                  </Text>
                                </View>
                              )
                            })}
                          </View>
                        ) : (
                          <View
                            style={{ display: 'flex', flexDirection: 'row' }}
                            break
                          >
                            {rows.jobFactor.map(() => {
                              return (
                                <View style={styles.colHeader}>
                                  <Text style={styles.tableCellData4}>
                                    {parseFloat(0).toFixed(2)}
                                  </Text>
                                </View>
                              )
                            })}
                          </View>
                        )}
                        <View style={styles.colHeader}>
                          <Text style={styles.tableCellData44}>
                            {parseFloat(scoretotal).toFixed(2)}
                          </Text>
                        </View>
                        <View style={styles.colHeader}>
                          <Text style={styles.tableCellData4}>
                            {parseFloat(scoretotal).toFixed(2)} /{' '}
                            {rows.jobTpm.totalScore}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )
              })}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    )
  }
}
export default PrintPDFReportGeneralJobs
