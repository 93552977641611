import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import {
  Search,
  Cancel,
  Person,
  AddCircle,
  CheckBox,
  Assignment,
} from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import format from 'date-fns/format'

import ViewAccounts from 'views/dialog/ViewAccounts'
import DeleteAccounts from 'views/dialog/DeleteAccounts'
import EnableAccounts from 'views/dialog/EnableAccounts'
import NewAcounts from 'views/dialog/NewAcounts'
import EditAcounts from 'views/dialog/EditAcounts'
import ViewProfilePrivilegesUserAccount from 'views/dialog/ViewProfilePrivilegesUserAccount'
import ViewProfilePrivilegesPsychometrics from 'views/dialog/ViewProfilePrivilegesPsychometrics'

import img from 'assets/img/undraw_handcrafts_user.png'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

export default function AcounntsListAdmin() {
  const classes = useStyles()
  const tableRef = React.createRef()

  const [idRow, setIdRow] = useState('')
  const [openView, setopenView] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [openNew, setopenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openPrivileges, setOpenPrivileges] = useState(false)
  const [openPrivilegesPsychometrics, setOpenPrivilegesPsychometrics] =
    useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication, urlLocalMediaProfile } =
    useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: '*',
        field: 'picture',
        filtering: false,
        render: (rowData) => (
          <img
            alt="avatar"
            src={
              rowData.picture === null
                ? img
                : urlLocalMediaProfile + rowData.picture
            }
            className={classes.imgUserProfileList}
          />
        ),
        width: '1%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'right' },
      },
      {
        title: 'Cuenta',
        field: 'name,lastName, companyName',
        filtering: false,
        render: (rowData) => {
          return (
            <Fragment>
              {rowData.name + ' ' + rowData.lastName}{' '}
              {rowData.companyName !== '' ? (
                <b style={{ fontSize: 11 }}>({rowData.companyName})</b>
              ) : (
                ''
              )}{' '}
            </Fragment>
          )
        },
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          textAlign: 'left',
          fontSize: 12,
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Correo',
        field: 'email',
        width: '25%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      /*  {
        title: "Perfil",
        field: "users[0].profile.name",
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },*/
      /*{
        title: "Ubicación",
        render: (rowData) => {
          return (
            <Fragment>
              <b style={{ fontSize: 11 }}>
                {rowData.city.state.name + "/" + rowData.city.name}
              </b>
            </Fragment>
          );
        },
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
          textAlign: "center",
        },
      },*/
      {
        title: 'Registrado',
        render: (rowData) => format(new Date(rowData.createdAt), 'dd-MM-yyyy'),
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Caduca',
        render: (rowData) => <b>{rowData.countdownDays} días</b>,
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Estado',
        field: 'status',
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? 'ACTIVO'
              : rowData.status === 2
              ? 'PENDIENTE '
              : 'INACTIVO'
          const color =
            rowData.status === 1
              ? 'green'
              : rowData.status === 2
              ? 'orange '
              : 'red'
          return <b style={{ color: color }}>{text}</b>
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  const handleCloseView = () => {
    setopenView(false)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleCloseAdd = () => {
    setOpenAdd(false)
  }

  const handleClosePrivileges = () => {
    setOpenPrivileges(false)
  }

  const handleClosePrivilegesPsychometrics = () => {
    setOpenPrivilegesPsychometrics(false)
  }

  const handleCloseNewUser = () => {
    setopenNew(false)
  }

  const handleCloseOpenEdit = () => {
    setOpenEdit(false)
  }

  let url = ''

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Person className={classes.iconWhite} /> Gestión de Cuentas
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.buttonRight}
                  onClick={(event) => {
                    setopenNew(true)
                  }}
                  startIcon={<AddCircle />}
                >
                  Agregar cuenta
                </Button>
              </Grid>
            </Grid>
            <MaterialTable
              title=""
              tableRef={tableRef}
              columns={state.columns}
              style={{
                boxShadow: 'unset',
                border: '1px solid rgb(241, 241, 241)',
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  if (query.search === '') {
                    url = `${urlServices}accounts?limit=${
                      query.pageSize
                    }&page=${query.page + 1}`
                  } else {
                    url = `${urlServices}accounts`
                  }
                  fetch(url, {
                    method: 'get',
                    headers: {
                      application: keyAplication,
                      Authorization: 'Bearer ' + keyAuthorization,
                    },
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        //data: result.data,
                        data: result.data.filter(function (obj) {
                          return Object.keys(obj).some(function (key) {
                            return obj[key]
                              ? obj[key]
                                  .toString()
                                  .toLowerCase()
                                  .includes(query.search)
                              : false
                          })
                        }),
                        page: result.page - 1,
                        totalCount: result.total,
                      })
                    })
                })
              }
              localization={{
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primera página',
                  previousTooltip: 'Previo',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Ultima página',
                },
                toolbar: {
                  // nRowsSelected: '{0} row(s) selected',
                  searchTooltip: 'Filtrar',
                  searchPlaceholder: 'Buscar',
                },
                header: {
                  actions: ' --- ',
                },
                body: {
                  emptyDataSourceMessage: 'No hay datos.',
                  filterRow: {
                    filterTooltip: 'Filtro',
                  },
                },
              }}
              actions={[
                {
                  icon: 'search',
                  tooltip: 'Ver',
                  onClick: (event, rowData) => {
                    setopenView(true)
                    setIdRow(rowData)
                  },
                },
                {
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, rowData) => {
                    setOpenEdit(true)
                    setIdRow(rowData)
                  },
                },
                (rowData2) => ({
                  icon: () => <CheckBox />,
                  tooltip: 'Permisos',
                  onClick: (event, rowData) => {
                    setOpenPrivileges(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData2.id === 1 ? true : false,
                }),
                (rowData2) => ({
                  icon: () => <Assignment />,
                  tooltip: 'Pruebas psicométricas',
                  onClick: (event, rowData) => {
                    setOpenPrivilegesPsychometrics(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData2.id === 1 ? true : false,
                }),
                /*{
                        icon: "delete",
                        tooltip: "Eliminar",
                        onClick: (event, rowData) => {
                          setOpenDelete(true);
                          setIdRow(rowData);
                        },
                      },*/
                (rowData1) => ({
                  icon: () => <Cancel />,
                  tooltip: 'Deshabilitar',
                  onClick: (event, rowData) => {
                    setOpenDelete(true)
                    setIdRow(rowData)
                  },
                  hidden:
                    rowData1.id === 1
                      ? true
                      : rowData1.status === 1
                      ? false
                      : true,
                }),
                (rowData2) => ({
                  icon: () => <AddCircle style={{ color: 'green' }} />,
                  tooltip: 'Habilitar',
                  onClick: (event, rowData) => {
                    setOpenAdd(true)
                    setIdRow(rowData)
                  },
                  hidden:
                    rowData2.id === 1
                      ? true
                      : rowData2.status === 0 || rowData2.status === 99
                      ? false
                      : true,
                }),
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              icons={tableIcons}
              options={{
                search: true,
                padding: 'default',
                filtering: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                headerStyle: {
                  background: '#F2F2F2',
                  padding: '1%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
                cellStyle: { border: '1px solid #DDDDDD' },
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openView ? (
        <ViewAccounts open={openView} exit={handleCloseView} id={idRow} />
      ) : (
        ''
      )}
      {openDelete ? (
        <DeleteAccounts
          open={openDelete}
          exit={handleCloseDelete}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}
      {openAdd ? (
        <EnableAccounts
          open={openAdd}
          exit={handleCloseAdd}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}

      {openNew ? (
        <NewAcounts
          open={openNew}
          exit={handleCloseNewUser}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}
      {openEdit ? (
        <EditAcounts
          open={openEdit}
          exit={handleCloseOpenEdit}
          callBackRefresh={RefreshTable}
          id={idRow}
        />
      ) : (
        ''
      )}
      {openPrivileges ? (
        <ViewProfilePrivilegesUserAccount
          open={openPrivileges}
          exit={handleClosePrivileges}
          id={idRow}
        />
      ) : (
        ''
      )}
      {openPrivilegesPsychometrics ? (
        <ViewProfilePrivilegesPsychometrics
          open={openPrivilegesPsychometrics}
          exit={handleClosePrivilegesPsychometrics}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}
    </GridContainer>
  )
}
