import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'

// core components
import SuperAdmin from 'layouts/SuperAdmin.js'
//import AdminUser from "layouts/AdminUser.js";
import Admin from 'layouts/Admin.js'
import Login from 'views/Login'
import PrintPDFResultsApplicantsTest from 'views/Print/PrintPDFResultsApplicantsTest'
import PrintPDFPassApplicantsTest from 'views/Print/PrintPDFPassApplicantsTest'
import PrintPDFReportApplicantsTest from 'views/Print/PrintPDFReportApplicantsTest'
import PrintPDFPassApplicantsJobs from 'views/Print/PrintPDFPassApplicantsJobs'
import PrintPDFReportGeneralJobs from 'views/Print/PrintPDFReportGeneralJobs'
import PrintPDFResultsIndividualJobs from 'views/Print/PrintPDFResultsIndividualJobs'

import NotFoundPage from 'views/404'
import RecoveryPassword from 'views/RecoveryPassword'

import 'assets/css/material-dashboard-react.css?v=1.8.0'
import { UrlServicesProvider } from 'components/UrlServicesContext'

const hist = createBrowserHistory()

ReactDOM.render(
  <UrlServicesProvider>
    <Router history={hist}>
      <Switch>
        <Route path="/" exact={true} component={Login} />
        <Route path="/superadmin" component={SuperAdmin} />
        <Route path="/admin" component={Admin} />
        <Route path="/login" exact={true} component={Login} />
        <Route path="/recovery" exact={true} component={RecoveryPassword} />
        <Route
          path="/printresults"
          exact={true}
          component={PrintPDFResultsApplicantsTest}
        />
        <Route
          path="/passapplicants"
          exact={true}
          component={PrintPDFPassApplicantsTest}
        />
        <Route
          path="/reportapplicants"
          exact={true}
          component={PrintPDFReportApplicantsTest}
        />
        <Route
          path="/passapplicantsjobs"
          exact={true}
          component={PrintPDFPassApplicantsJobs}
        />
        <Route
          path="/reportgeneraljobs"
          exact={true}
          component={PrintPDFReportGeneralJobs}
        />
        <Route
          path="/printresultsindividualjobs"
          exact={true}
          component={PrintPDFResultsIndividualJobs}
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  </UrlServicesProvider>,
  document.getElementById('root')
)
