import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import {
  Search,
  Cancel,
  Layers,
  AddCircle,
  Assignment,
  PersonAdd,
  HowToReg,
} from '@material-ui/icons'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable from 'material-table'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import format from 'date-fns/format'

import NewJobPsychometrics from 'views/dialog/NewJobPsychometrics'
import EditJobPsychometrics from 'views/dialog/EditJobPsychometrics'
import EnablePsychometrics from 'views/dialog/EnablePsychometrics'
import DisablePsychometrics from 'views/dialog/DisablePsychometrics'
import SelectTestPsychometrics from 'views/dialog/SelectTestPsychometrics'
import EnableTestJobsPsychometrics from 'views/dialog/EnableTestJobsPsychometrics'
import EnableApplicantJobsPsychometrics from 'views/dialog/EnableApplicantJobsPsychometrics'
import ViewApplicantTestPsychometrics from 'views/dialog/ViewApplicantTestPsychometrics'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

export default function JobsPsychometrics() {
  let url = ''
  const classes = useStyles()
  const tableRef = React.createRef()

  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [openHabilitar, setOpenHabilitar] = useState(false)
  const [openDesHabilitar, setOpenDesHabilitar] = useState(false)
  const [openTestPsychometrics, setOpenTestPsychometrics] = useState(false)
  const [openApplicants, setOpenApplicants] = useState(false)
  const [openTests, setOpenTests] = useState(false)
  const [tests, setTests] = useState(false)
  const [openApplicantsTests, setOpenApplicantsTests] = useState(false)
  const [activatePhoto, setActivatePhoto] = useState(false)
  const [activateApplicantreport, setActivateApplicantreport] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Cargo',
        field: 'name',
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
        },
      },
      {
        title: 'Puntaje total',
        field: 'totalScore',
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Equivalencia',
        field: 'equivalent',
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Contraseñas aleatorias',
        field: 'managePasswords',
        width: '10%',
        render: (rowData) => {
          const text = rowData.managePasswords ? 'SI' : 'NO'
          return <b>{text}</b>
        },
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Registrado',
        render: (rowData) =>
          format(new Date(rowData.createdAt), 'dd-MM-yyyy kk:mm:ss'),
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Estado',
        field: 'status',
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? 'PUBLICADO'
              : rowData.status === 99
              ? 'NO PUBLICADO'
              : 'INACTIVO'
          const color =
            rowData.status === 1
              ? 'green'
              : rowData.status === 99
              ? 'orange'
              : 'red'
          return <b style={{ color: color }}>{text}</b>
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  const handleCloseNew = () => {
    setOpenNew(false)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const handleCloseHabilitar = () => {
    setOpenHabilitar(false)
  }

  const handleCloseDesHabilitar = () => {
    setOpenDesHabilitar(false)
  }

  const handleCloseTestPsychometrics = () => {
    setOpenTestPsychometrics(false)
    RefreshTable()
  }

  const handleCloseTests = () => {
    setOpenTests(false)
  }

  const handleCloseApplicants = () => {
    setOpenApplicants(false)
  }

  const handleCloseApplicantsTests = () => {
    setOpenApplicantsTests(false)
  }

  const testsSend = (data) => {
    setTests(data)
    setOpenApplicants(true)
  }

  const handleOpenJob = (event, option, rowData) => {
    const urlAccount = `${urlServices}accounts/${localStorage.getItem(
      'account_id'
    )}`

    fetch(urlAccount, {
      method: 'get',
      headers: {
        application: keyAplication,
        Authorization: 'Bearer ' + keyAuthorization,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result !== undefined) {
          setActivatePhoto(result.activatePhoto)
          setActivateApplicantreport(result.activateApplicantreport)
          if (option === 1) setOpenNew(true)
          else {
            setIdRow(rowData)
            setOpenEdit(true)
          }
        }
      })
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Gestión de cargos
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.buttonRight}
                  onClick={(e) => handleOpenJob(e, 1)}
                  startIcon={<AddCircle />}
                >
                  Nuevo cargo
                </Button>
              </Grid>
            </Grid>
            <MaterialTable
              title=""
              columns={state.columns}
              tableRef={tableRef}
              style={{
                boxShadow: 'unset',
                border: '1px solid rgb(241, 241, 241)',
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  if (query.search === '') {
                    url = `${urlServices}jobs-tpm/account/${localStorage.getItem(
                      'account_id'
                    )}?limit=${query.pageSize}&page=${query.page + 1}`
                  } else {
                    url = `${urlServices}jobs-tpm/account/${localStorage.getItem(
                      'account_id'
                    )}`
                  }
                  fetch(url, {
                    method: 'get',
                    headers: {
                      application: keyAplication,
                      Authorization: 'Bearer ' + keyAuthorization,
                    },
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        //data: result.data,
                        data:
                          result.data !== undefined
                            ? result.data.filter(function (obj) {
                                return Object.keys(obj).some(function (key) {
                                  return obj[key]
                                    ? obj[key]
                                        .toString()
                                        .toLowerCase()
                                        .includes(query.search)
                                    : false
                                })
                              })
                            : [],
                        page: result.page !== undefined ? result.page - 1 : 0,
                        totalCount:
                          result.total !== undefined ? result.total : 0,
                      })
                    })
                })
              }
              localization={{
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primera página',
                  previousTooltip: 'Previo',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Ultima página',
                },
                toolbar: {
                  // nRowsSelected: '{0} row(s) selected',
                  searchTooltip: 'Filtrar',
                  searchPlaceholder: 'Buscar',
                },
                header: {
                  actions: ' --- ',
                },
                body: {
                  emptyDataSourceMessage: 'No hay datos.',
                  filterRow: {
                    filterTooltip: 'Filtro',
                  },
                },
              }}
              actions={[
                (rowData1) => ({
                  icon: () => <HowToReg />,
                  tooltip: 'Candidatos',
                  onClick: (event, rowData) => {
                    setOpenApplicantsTests(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData1.status === 1 ? false : true,
                }),
                (rowData1) => ({
                  icon: () => <PersonAdd />,
                  tooltip: 'Agregar candidatos',
                  onClick: (event, rowData) => {
                    setOpenApplicants(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData1.status === 1 ? false : true,
                }),
                (rowData2) => ({
                  icon: () => <Assignment />,
                  tooltip: 'Pruebas',
                  onClick: (event, rowData) => {
                    setOpenTestPsychometrics(true)
                    setIdRow(rowData)
                  },
                }),
                (rowData1) => ({
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, rowData) => handleOpenJob(event, 2, rowData),
                  hidden: rowData1.status === 1 ? true : false,
                }),
                (rowData1) => ({
                  icon: () => <Cancel />,
                  tooltip: 'Deshabilitar',
                  onClick: (event, rowData) => {
                    setOpenDesHabilitar(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData1.status === 1 ? false : true,
                }),
                (rowData2) => ({
                  icon: () => (
                    <AssignmentTurnedInIcon style={{ color: 'green' }} />
                  ),
                  tooltip: 'Publicar',
                  onClick: (event, rowData) => {
                    setOpenHabilitar(true)
                    setIdRow(rowData)
                  },
                  hidden:
                    rowData2.status === 0 || rowData2.status === 99
                      ? false
                      : true,
                }),
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              icons={tableIcons}
              options={{
                search: true,
                padding: 'default',
                filtering: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                headerStyle: {
                  background: '#F2F2F2',
                  padding: '1%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
                cellStyle: { border: '1px solid #DDDDDD' },
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openNew ? (
        <NewJobPsychometrics
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
          activatePhoto={activatePhoto}
          activateApplicantreport={activateApplicantreport}
        />
      ) : (
        ''
      )}
      {openEdit ? (
        <EditJobPsychometrics
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
          activatePhoto={activatePhoto}
          activateApplicantreport={activateApplicantreport}
        />
      ) : (
        ''
      )}
      {openHabilitar ? (
        <EnablePsychometrics
          open={openHabilitar}
          exit={handleCloseHabilitar}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}
      {openDesHabilitar ? (
        <DisablePsychometrics
          open={openDesHabilitar}
          exit={handleCloseDesHabilitar}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ''
      )}
      {openTestPsychometrics ? (
        <SelectTestPsychometrics
          open={openTestPsychometrics}
          exit={handleCloseTestPsychometrics}
          id={idRow}
        />
      ) : (
        ''
      )}
      {openTests ? (
        <EnableTestJobsPsychometrics
          open={openTests}
          exit={handleCloseTests}
          tests={testsSend}
          id={idRow}
        />
      ) : (
        ''
      )}

      {openApplicants ? (
        <EnableApplicantJobsPsychometrics
          open={openApplicants}
          exit={handleCloseApplicants}
          tests={tests}
          id={idRow}
        />
      ) : (
        ''
      )}

      {openApplicantsTests ? (
        <ViewApplicantTestPsychometrics
          open={openApplicantsTests}
          exit={handleCloseApplicantsTests}
          jobsId={idRow.id}
        />
      ) : (
        ''
      )}
    </GridContainer>
  )
}
