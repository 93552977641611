import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import FormControl from '@material-ui/core/FormControl'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import {
  BorderColor,
  ArrowRightAlt,
  Save,
  WatchLater,
  Replay,
  //VisibilityOff,
  //Hearing,
  Person,
} from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { Animated } from 'react-animated-css'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Alert, AlertTitle } from '@material-ui/lab'
import successImg from 'assets/img/success.gif'
import test from 'assets/img/undraw_Speed_test_re_pe1f.png'
import exit from 'assets/img/undraw_towing_6yy4.png'
import LoadGender from 'components/services/LoadGender'
import TextField from '@material-ui/core/TextField'
import Slide from '@material-ui/core/Slide'
import Camera from 'views/Camera/Camera'
import CameraAutoCapture from 'views/Camera/CameraAutoCapture'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function StartTestPsychometrics(props) {
  const classes = useStyles()

  const [next, setNext] = useState(0)
  const [nextSection, setNextSection] = useState(0)
  const [nextScale, setNextScale] = useState(0)
  const [rowsSection, setRowsSection] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [disabledNext, setDisabledNext] = useState(false)
  const [testResult, setTestResult] = useState([])
  const [testTpm, setTestTpm] = useState({})
  const [mensageExit, setMensageExit] = useState(false)
  const [user, setUser] = useState('')
  const [colorTimer, setColorTimer] = useState('rgb(34, 34, 34)')
  const [timerSave, setTimerSave] = useState(0)
  const [insertNext, setInsertNext] = useState(false)
  const [DisabledStart, setDisabledStart] = useState(true)
  const [returnLogin, setReturnLogin] = useState(false)
  const [testPersonal, setTestPersonal] = useState(true)
  const [disabledDeleteSelectGD, setDisabledDeleteSelectGD] = useState(true)
  const [question, setQuestion] = useState('')
  const [testPersonalGender, setTestPersonalGender] = useState(false)

  const [hrs, setHrs] = useState(0)
  const [mins, setMinutes] = useState(0)
  const [secs, setSeconds] = useState(0)
  const [timer0, setTimer0] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    limit: 1,
    offset: 0,
    radioValue: null,
    timer: 0,
    genderData: '',
  })

  const [arrThDemo, setArrThDemo] = useState(Array(3).fill(''))
  const [arrTh, setArrTh] = useState(Array(100).fill(''))
  const [error, setError] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [cameraInit, setCameraInit] = useState(true)
  const [testCamera, setTestCamera] = useState(true)
  const [photo, setPhoto] = useState(null)
  const [cameraKey, setCameraKey] = useState(1)
  const [userMediaError, setUserMediaError] = useState(false)

  useEffect(() => {
    if (
      props.id.test.id === 1 ||
      props.id.test.id === 3 ||
      props.id.test.id === 6 ||
      props.id.test.id === 7 ||
      props.id.test.id === 10 ||
      props.id.test.id === 13 ||
      props.id.test.id === 14 ||
      props.id.test.id === 16 ||
      props.id.test.id === 17 ||
      props.id.test.id === 18 ||
      props.id.test.id === 20 ||
      props.id.test.id === 21
    ) {
      setTestPersonal(false)
      setDisabledStart(false)
    }

    axios
      .get(
        `${urlServices}jobs-tpm/${props.id.jobId}/test/${props.id.test.id}/random`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        let outData = {}

        setTimerSave(response.data.jobTestTpm.time)

        setTestTpm((prevState) => {
          // Object.assign también funcionaría
          return { ...prevState, ...response.data.jobTestTpm.test }
        })

        outData = iterationTestTpm(response.data.jobTestTpm.test, 0, 0)
        //console.log('outData', outData)

        setRowsSection(outData)
        setTotalPages(Math.ceil(outData.length / 1))

        // console.log(Math.ceil(outData.length / 1))

        if (response.data.jobTestTpm.time > 0) {
          let timerFinal =
            parseFloat(response.data.jobTestTpm.time) -
            parseFloat(response.data.timer)

          let secData = timerFinal * 60,
            horas = Math.floor(secData / 3600),
            minutos = Math.floor((secData / 60) % 60),
            segundos = secData % 60

          setHrs(horas)
          setMinutes(minutos)
          setSeconds(segundos)
          setTimer0(false)
        }
        // else {
        //   if (props.id.test.id !== 14 && props.id.test.id !== 16) {
        //     setTimer0(true)
        //   }
        // }

        setUser({
          name: props.id.user.name + ' ' + props.id.user.lastName,
          document: props.id.user.documentId,
        })
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRowsSection([])
        }
      })
  }, [keyAplication, urlServices])

  useEffect(() => {
    if (!timer0) {
      if (next > 0) {
        let sampleInterval = setInterval(() => {
          if (secs > 0) {
            setSeconds(secs - 1)
          }
          if (secs === 0) {
            if (mins === 0) {
              if (hrs === 0) {
                const totalScales = testTpm.sections[nextSection]?.scales.length
                for (let s = nextScale; s < totalScales; s++) {
                  const totalQuestions =
                    testTpm.sections[nextSection].scales[s].questions.length
                  for (let q = next - 1; q < totalQuestions; q++) {
                    const question =
                      testTpm.sections[nextSection].scales[s].questions[q]
                    const testResult = handleRadioChange(
                      { target: { value: true } },
                      question,
                      {
                        id: 0,
                      }
                    )

                    insertSave(question.id, testResult)
                  }
                }

                const scales = testTpm.sections[nextSection]?.scales.length
                const questions = iterationTestTpm(testTpm, 1, scales)

                if (questions.length === 0) {
                  UpdateStatusTest(1)
                  clearInterval(sampleInterval)
                  setNext(totalPages + 1)
                } else {
                  setRowsSection(questions)
                }
              } else {
                setHrs(hrs - 1)
                setMinutes(59)
              }
            } else {
              setMinutes(mins - 1)
              setSeconds(59)
            }
          }
          if (hrs === 0 && mins <= 2) {
            setColorTimer('red')
          }
        }, 1000)
        return () => {
          clearInterval(sampleInterval)
        }
      }
    }
  })

  const handleNext = (pro) => async (event) => {
    let validNext = false

    if (props.id.takePhoto && next > 0) {
      await navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === 'videoinput') {
              if (device.label) {
                validNext = true
              } else {
                validNext = false
                validUserMedia('error')
              }
            }
          })
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`)
          validNext = false
          validUserMedia('error')
        })
    } else {
      validNext = true
    }

    if (validNext) {
      if (next > 0) {
        setValues({
          ...values,
          offset: values.limit,
          limit: values.limit + 1,
          radioValue: null,
        })
        // setDisabledBack(false);
      } else {
        UpdateStatusTest(0)
        props.callBackRefreshPsychometrics()
      }

      if (cameraInit && props.id.takePhoto) {
        insertSavePhoto(props.id)
      } else {
        setNext(next + 1)

        if (insertNext) {
          insertSave(question.id, testResult)
        }

        if (!timer0) {
          UpdateStatusTestTimer()
        }

        setDisabledNext(true)
        setDisabledDeleteSelectGD(true)

        if (next === rowsSection.length) {
          setValues({
            ...values,
            offset: 0,
            limit: 1,
            radioValue: null,
          })

          setNextScale(nextScale + 1)
          setRowsSection(iterationTestTpm(testTpm, 1, nextScale + 1))
        }
      }
    }
  }

  function iterationTestTpm(test, next, nextScaleAux) {
    let nextSectionAux = nextSection

    if (!test.sections[nextSectionAux]?.scales[nextScaleAux]) {
      nextSectionAux += 1
      nextScaleAux = 0
    }

    if (test.sections[nextSectionAux]) {
      setNextSection(nextSectionAux)
      setNextScale(nextScaleAux)

      if (test.sections[nextSectionAux].time > 0) {
        let secData = test.sections[nextSectionAux].time * 60,
          horas = Math.floor(secData / 3600),
          minutos = Math.floor((secData / 60) % 60),
          segundos = secData % 60

        setHrs(horas)
        setMinutes(minutos)
        setSeconds(segundos)
        setTimer0(false)
      } else {
        setTimer0(true)
      }

      setTotalPages(
        test.sections[nextSectionAux].scales[nextScaleAux].questions.length
      )
      setNext(next)
      return test.sections[nextSectionAux].scales[nextScaleAux].questions
    } else {
      return []
    }
  }

  /*const handleBack = (pro) => (event) => {
    setValues({
      ...values,
      offset: 0,
      limit: 1,
      radioValue: null,
    });

    setDisabledBack(true);
    setNext(1);
  };*/

  const UpdateStatusTestTimer = async () => {
    var hora = hrs + ':' + mins + ':' + secs
    var parts = hora.split(':')
    var total = parseInt(parts[0]) * 60 + parseInt(parts[1])

    const data = {
      timer: parseFloat(timerSave) - parseFloat(total),
    }

    await axios
      .put(`${urlServices}jobs-users-tpm/${props.id.id}`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {},
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const UpdateStatusTest = async (n) => {
    const data = {
      status: n === 0 ? 2 : 3,
    }

    await axios
      .put(`${urlServices}jobs-users-tpm/${props.id.id}`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          //props.callBackRefreshPsychometrics()
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const insertSave = (question, testResult) => {
    //console.log('insertSave', testResult)

    setDisabledNext(true)
    let n = 0

    // axios
    //   .get(
    //     `${urlServices}tests-results-tpm/job/${props.id.jobId}/question/${question}`,
    //     {
    //       headers: {
    //         application: keyAplication,
    //         Authorization: 'Bearer ' + keyAuthorization,
    //       },
    //     }
    //   )
    //   .then((response3) => {
    //     if (response3.data.id === undefined) {
    testResult.forEach((items) => {
      let data = {
        rightAnswer: items.rightAnswer,
        selectedAnswer: items.selectedAnswer,
        score: items.score,
        answerId: items.answerId,
        questionId: items.questionId,
        factorId: items.factorId,
        testId: items.testId,
        jobId: items.jobId,
      }

      if (
        testTpm.sections[nextSection].scales[nextScale].guidelines ===
          'guidelinesescalath8' ||
        testTpm.sections[nextSection].scales[nextScale].guidelines ===
          'guidelinesescalath9'
      ) {
        data.answers = items.answers
      }

      insert(data)
      n++
    })

    if (n === testResult.length) {
      setTestResult([])
      // setDisabledNext(false);
      setInsertNext(false)
    }
    //   } else {
    //     deleteSave()

    //     testResult.forEach((items) => {
    //       const data = {
    //         rightAnswer: items.rightAnswer,
    //         selectedAnswer:
    //           items.selectedAnswer === '' ? false : items.selectedAnswer,
    //         score: items.score,
    //         answerId: items.answerId,
    //         questionId: items.questionId,
    //         factorId: items.factorId,
    //         testId: items.testId,
    //         jobId: items.jobId,
    //       }
    //       insert(data)
    //       n++
    //     })

    //     if (n === testResult.length) {
    //       setTestResult([])
    //       // setDisabledNext(false);
    //       setInsertNext(false)
    //     }
    //   }
    // })
    // .catch((e) => {
    //   console.log(e)
    //   if (e.response.status === 404) {
    //   } else {
    //     console.log(e)
    //   }
    // })
  }

  const insert = async (data) => {
    await axios
      .post(`${urlServices}tests-results-tpm`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          // console.log("REGISTRADO");
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleSaved = (pro) => (event) => {
    insertSave(question.id, testResult)
    setNext(next + 1)
    UpdateStatusTest(1)
    //props.callBackRefreshPsychometrics()
  }

  const handleTestPersonal = (pro) => (event) => {
    axios
      .get(`${urlServices}users/${localStorage.getItem('id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.data.genderId === null) {
            setTestPersonalGender(true)
          } else {
            setDisabledStart(false)
          }
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })

    setTestPersonal(false)
  }

  const handleTestPersonalGD = (pro) => (event) => {
    setTestPersonal(false)
    setDisabledStart(false)
  }

  const handleExit = (pro) => (event) => {
    setMensageExit(true)
  }

  const handleContinue = (pro) => (event) => {
    setMensageExit(false)
  }

  const handleContinueUserMedia = (pro) => (event) => {
    photo || setCameraInit(true)
    setUserMediaError(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    if (!timer0) {
      UpdateStatusTestTimer()
    }
    props.exit()
  }

  const handleRadioChange = (event, question, answer) => {
    if (event.target.value !== undefined) {
      let out = []

      if (
        props.id.test.id === 8 ||
        props.id.test.id === 13 ||
        props.id.test.id === 20
      ) {
        let out1 = [],
          out2 = [],
          Rows = [...testResult]

        rowsSection.forEach((row) => {
          if (row.id === question.id) {
            row.answers.forEach((items2) => {
              if (items2.valueText === 'MÁS') {
                out1.push(items2)
              }

              if (items2.valueText === 'MENOS') {
                out2.push(items2)
              }
            })
          }
        })

        rowsSection.forEach((row) => {
          if (row.id === question.id) {
            row.answers.forEach((items2) => {
              if (items2.id === answer.id && out1.length === 0) {
                items2.valueDefault = event.target.checked
                items2.checkedDisabled = true
                items2.valueText = 'MÁS'

                Rows.push({
                  rightAnswer: answer.rightAnswer,
                  selectedAnswer: true,
                  score: 1,
                  answerId: items2.id,
                  questionId: answer.questionId,
                  factorId:
                    testTpm.sections[nextSection].scales[nextScale].factorId,
                  testId: testTpm.sections[nextSection].testId,
                  jobId: props.id.jobId,
                  valueText: 'MÁS',
                })

                setDisabledDeleteSelectGD(false)
              } else if (items2.id === answer.id && out2.length === 0) {
                items2.valueDefault = event.target.checked
                items2.valueText = 'MENOS'
                items2.checkedDisabled = true

                Rows.push({
                  rightAnswer: answer.rightAnswer,
                  selectedAnswer: true,
                  score: 2,
                  answerId: items2.id,
                  questionId: answer.questionId,
                  factorId:
                    testTpm.sections[nextSection].scales[nextScale].factorId,
                  testId: testTpm.sections[nextSection].testId,
                  jobId: props.id.jobId,
                  valueText: 'MENOS',
                })

                let sinSelect = 1

                rowsSection.forEach((row) => {
                  if (row.id === question.id) {
                    row.answers.forEach((items2) => {
                      Rows.forEach((items3) => {
                        if (
                          items3.answerId !== items2.id &&
                          items2.valueText !== 'MÁS' &&
                          items2.valueText !== 'MENOS'
                        ) {
                          if (sinSelect !== 1) {
                            Rows.push({
                              rightAnswer: answer.rightAnswer,
                              selectedAnswer: true,
                              score: 0,
                              answerId: items2.id,
                              questionId: answer.questionId,
                              factorId:
                                testTpm.sections[nextSection].scales[nextScale]
                                  .factorId,
                              testId: testTpm.sections[nextSection].testId,
                              jobId: props.id.jobId,
                              valueText: undefined,
                            })

                            sinSelect = 1
                          } else {
                            sinSelect++
                          }
                        }
                      })
                    })
                  }
                })

                setDisabledNext(false)
              }
            })
          }
        })

        setTestResult(Rows)
        setQuestion(question)
        setInsertNext(true)
      } else {
        question.answers.forEach((items2) => {
          if (items2.id === answer.id) {
            items2.newvalue = event.target.checked
            items2.valueDefault = event.target.checked

            out.push({
              rightAnswer: answer.rightAnswer,
              selectedAnswer: items2.newvalue,
              score: answer.score,
              answerId: answer.id,
              questionId: answer.questionId,
              factorId:
                testTpm.sections[nextSection].scales[nextScale].factorId,
              testId: testTpm.sections[nextSection].testId,
              jobId: props.id.jobId,
            })
          } else {
            if (question.questiontypeId === 1) {
              items2.newvalue = false
              items2.valueDefault = false
            }

            out.push({
              rightAnswer: items2.rightAnswer,
              selectedAnswer: items2.newvalue || false,
              score: items2.score,
              answerId: items2.id,
              questionId: items2.questionId,
              factorId:
                testTpm.sections[nextSection].scales[nextScale].factorId,
              testId: testTpm.sections[nextSection].testId,
              jobId: props.id.jobId,
            })
          }
        })

        setTestResult(out)
        setQuestion(question)
        setInsertNext(true)
        setDisabledNext(false)

        return out
      }
    }
  }

  const handleDeleteSelectGD = () => () => {
    rowsSection.forEach((row) => {
      row.answers.forEach((items2) => {
        items2.valueDefault = false
        items2.valueText = undefined
        items2.checkedDisabled = undefined
      })
    })

    setDisabledNext(true)
    setDisabledDeleteSelectGD(true)
    setTestResult([])
  }

  const getResponseGender = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        genderData: pro,
      })

      const dataValue = {
        genderId: pro.id,
      }

      axios
        .put(`${urlServices}users/${localStorage.getItem('id')}`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error)
          }
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })

      setDisabledStart(false)
    }
  }

  const handleChangeTH = (index, event, demo, question) => {
    let newValues = []

    if (demo) {
      newValues = [...arrThDemo.filter((a) => a !== '')]
      newValues[index] = event.target.value
      setArrThDemo(newValues)
    } else {
      newValues = [...arrTh.filter((a) => a !== '')]
      newValues[index] = event.target.value
      setArrTh(newValues)
    }

    const out = [
      {
        rightAnswer: question.answers[0].rightAnswer,
        selectedAnswer: true,
        score: question.answers[0].score,
        answerId: question.answers[0].id,
        questionId: question.answers[0].questionId,
        factorId: testTpm.sections[nextSection].scales[nextScale].factorId,
        testId: testTpm.sections[nextSection].testId,
        jobId: props.id.jobId,
        answers: JSON.stringify(newValues),
      },
    ]

    setTestResult(out)
    setQuestion(question)
    setInsertNext(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const capturePhoto = (img) => {
    setTestCamera(img ? false : true)
    setPhoto(img)
  }

  const insertSavePhoto = (jobUserId) => {
    const data = {
      picture: photo,
      jobUserId: jobUserId.id,
    }

    axios
      .post(`${urlServices}jobs-users-photos`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {
          setCameraInit(false)
          setTestCamera(false)
          return true
        },
        (error) => {
          setError('error')
          setMensaje(
            'Existen inconvenientes en el registro de la foto. Por favor, notifique al administrador.'
          )
          setOpenAlert(true)
          setCameraKey(Math.floor(Math.random() * 100))
          setTestCamera(true)
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const validUserMedia = (response) => {
    setCameraInit(false)
    setUserMediaError(response === 'error' || true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      // onClose={handleCloseDialog(true)}
      keepMounted
      scroll="body"
      style={{ zIndex: 100 }}
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <Slide direction="down" in={openAlert} mountOnEnter unmountOnExit>
        <Alert
          onClose={handleClose}
          severity={error === 'error' ? 'error' : 'success'}
          elevation={6}
          variant="filled"
        >
          {mensaje}
        </Alert>
      </Slide>
      <DialogContent dividers className={classes.styleDialogContent}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <BorderColor className={classes.iconWhite} />{' '}
              <span style={{ textTransform: 'uppercase' }}>
                {props.id.test.name}
              </span>
            </div>
          </CardHeader>
          <CardBody>
            {props.id.takePhoto && cameraInit ? (
              <Grid item xs={12} sm={12} style={{ padding: '15px 30px' }}>
                <Animated
                  animationIn="fadeIn"
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <Grid
                    container
                    spacing={1}
                    className={classes.containerProfile}
                    style={{
                      margin: '15px 0 15px 0',
                    }}
                  >
                    <Grid item xs={12} sm={12} md={7}>
                      <Fragment>
                        <div className={classes.fontParagraph}>
                          <b>Señor aspirante</b>
                          <br></br>
                          <br></br>
                          Antes de iniciar es necesario que <b>usted</b> tome
                          una foto con su dispositivo presionando el botón{' '}
                          <b>Tomar foto</b>. Además, se tomarán varias fotos de
                          forma aleatoria hasta que finalice la prueba.
                          <br></br>
                          <br></br>Una vez que tome la foto se activa el botón{' '}
                          <b>Iniciar</b> para rendir la prueba.
                        </div>
                      </Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <center>
                        <Camera
                          key={cameraKey}
                          onCapture={capturePhoto}
                          userMedia={validUserMedia}
                        />
                      </center>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <br></br>
                      <center>
                        <Button
                          type="submit"
                          className={classes.buttonLeftSteps2}
                          onClick={handleCloseDialog(true)}
                        >
                          Salir
                        </Button>
                        <Button
                          type="submit"
                          className={classes.buttonSubmit}
                          onClick={handleNext()}
                          disabled={testCamera ? true : false}
                          endIcon={<ArrowRightAlt />}
                        >
                          Iniciar
                        </Button>
                      </center>
                    </Grid>
                  </Grid>
                </Animated>
              </Grid>
            ) : mensageExit ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Confirmación</AlertTitle>
                      Puede salir de la prueba sin ningún problema, las
                      preguntas realizadas serán registradas para{' '}
                      <b>seguir en otro momento</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinue(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            ) : userMediaError ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Advertencia</AlertTitle>
                      No puede continuar con la prueba, se requiere permisos
                      para acceder a la cámara. Cuando lo permita presione el
                      botón <b>Continuar la prueba</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinueUserMedia(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                {next === 0 ? (
                  <Fragment>
                    <Grid item xs={12} sm={5}>
                      <center>
                        <img
                          src={test}
                          alt="test"
                          style={{ marginTop: 150, width: 300 }}
                        />
                      </center>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {props.id.test.id === 1 ? (
                        <Fragment>
                          {testPersonal ? (
                            <div className={classes.fontParagraph}>
                              <br></br>
                              <b>PRUEBA DE PERSONALIDAD LABORAL (FP)</b>
                              <br></br>
                              De: Raymond B. Cattell, Herbert W. Eber y Maurice
                              M. Tatsuoka
                              <br></br>
                              Para análisis de la personalidad
                              <br></br>
                              <br></br>
                              <b>Señor aspirante</b>
                              <br></br>Este es un cuestionario de sus actitudes,
                              lo que hace o lo que piensa acerca de ciertas
                              situaciones, las personas piensan de una manera u
                              otra.
                              <br></br>
                              Por lo tanto, no existen respuestas "correctas" o
                              "incorrectas" a las preguntas de este formulario.
                              <br></br>
                              Adelante aparecen <b>cinco</b> preguntas de ensayo
                              que usted contestará, para estar seguro de que
                              comprende lo que debe hacer en el cuestionario.
                              Marque la respuesta correcta dando un "click" con
                              el botón de su "mouse" o "ratón". Observará que
                              una vez que seleccionó su respuesta, esta se
                              vuelve de color rojo y después de un segundo pasa
                              a la siguiente pregunta.
                              <br></br>
                              Usted tendrá <b>tres</b> posibles respuestas para
                              cada pregunta, que debe contestar "Sí" o "No" (o
                              "A" o "C") dando un click u oprimiendo la tecla
                              correspondiente a esa letra. Solamente debe marcar
                              la respuesta en el centro "B" cuando le sea
                              imposible decir "Sí" o "No".
                              <br></br>
                              Conteste ahora las <b>cinco</b> siguientes
                              preguntas de ensayo:
                            </div>
                          ) : (
                            <div className={classes.fontParagraph}>
                              <br></br>
                              En la siguientes pantallas encontrará 187
                              preguntas similares a las que aparecieron ahora.
                              Para contestarlas tenga presente estas reglas:
                              <br></br>
                              <br></br>
                              <b>1.</b> CONTESTE LAS PREGUNTAS TAN FRANCA Y
                              HONESTAMENTE COMO LE SEA POSIBLE. No existe
                              ventaja en crear una mejor impresión personal. No
                              marque una respuesta que no sea cierta por creer
                              que "es lo que debe decir".
                              <br></br>
                              <b>2.</b> CONTESTE CON LA MAYOR RAPIDEZ POSIBLE.
                              No dedique tiempo a meditar sobre las preguntas,
                              anote su primera reacción y pase de allí a la
                              próxima pregunta.
                              <br></br>
                              <b>3.</b> Marque la respuesta (Sí o A) o la
                              respuesta (No o C) con un click a la mayoría de
                              las preguntas. Solamente marque la respuesta "B"
                              cuando le sea imposible contestar "Sí" o "No"
                              aunque en algunos casos le será difícil escoger.
                              <br></br>
                              <b>4.</b> CONTESTE TODAS LAS PREGUNTAS SIN
                              EXCEPCION. Habrá preguntas que no reflejen sus
                              ideas, pero debe contestarlas todas. Como habrá
                              observado, la única manera de seguir adelante con
                              las preguntas es contestándolas.
                              <br></br>
                              <br></br>
                              <b>SUS RESPUESTAS SERAN CONFIDENCIALES</b>
                            </div>
                          )}
                        </Fragment>
                      ) : props.id.test.id === 3 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>TEST EQ</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>La mayoría de las personas leemos o
                            escuchamos hablar permanentemente sobre la
                            importancia creciente de facultades tales como la
                            motivación, proactividad, liderazgo, cooperación,
                            compromiso, optimismo, adaptabilidad, autocontrol y
                            capacidad del trabajo en equipo. La Inteligencia
                            Emocional (IE) justamente concierne a éstas y otras
                            aptitudes íntimamente relacionadas.
                            <br></br>
                            <br></br>Muchas personas de gran preparación
                            intelectual, pero faltas de inteligencia emocional,
                            terminan trabajando a las órdenes de personas que
                            tienen un CI menor, pero mayor inteligencia
                            emocional.
                            <br></br>
                            <br></br>La Inteligencia Emocional esencialmente
                            proporciona a las personas capacidad y habilidad
                            para regular sus fuerzas e impulsos emocionales
                            contraproducentes, muchos de ellos inconscientes,
                            los cuales boicotean sus relaciones y calidad de
                            vida, por tanto, su desarrollo y progreso en lo
                            educativo, como en la actividad profesional o
                            laboral.
                            <br></br>
                            <br></br>Para efectos de este cuestionario por
                            inteligencia emocional se entiende "la capacidad de
                            sentir, entender y aplicar eficazmente el poder y la
                            agudeza de las emociones como fuente de energía
                            humana, información, conexión e influencia."
                            <br></br>
                            <br></br>* CONTESTE LAS PREGUNTAS TAN FRANCA Y
                            HONRADAMENTE COMO LE SEA POSIBLE. No existe ventaja
                            en crear una mejor impresión personal. No seleccione
                            una respuesta que no sea cierta por creer que "es lo
                            que debe decir".
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 6 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>Prueba de Personalidad Clínica (M)</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>Este inventario consta de oraciones o
                            proposiciones enumeradas. Lea cada una y decida si,
                            en su caso, es <b>cierta</b> o <b>falsa</b>. Trate
                            de ser SINCERO CONSIGO MISMO y use su propio
                            criterio.
                            <br></br>
                            <br></br>1. CONTESTE LAS PREGUNTAS TAN FRANCA Y
                            HONRADAMENTE COMO LE SEA POSIBLE. No existe ventaja
                            en crear una mejor impresión personal. No marque una
                            respuesta que no sea cierta por creer que &quot;es
                            lo que debe decir&quot;.
                            <br></br>2. CONTESTE CON LA MAYOR RAPIDEZ POSIBLE.
                            No dedique tiempo a meditar sobre las preguntas.
                            Anote su primera reacción y pase de allí a la
                            próxima pregunta.
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 7 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>Test de Clima Laboral (MS)</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>A continuación encontrará unas frases
                            relacionadas con el trabajo. Aunque están pensadas
                            para muy distintos ambientes laborales, es posible
                            que algunas no se ajusten del todo al lugar donde
                            usted trabaja. Trate de acomodarlas a su propio caso
                            y decida si son ciertas o falsas en relación con su
                            centro de trabajo. En las frases, el jefe es la
                            persona de autoridad (capataz, encargado,
                            supervisor, director, etc.) con quien usted se
                            relaciona. La palabra empleado se utiliza en sentido
                            general, aplicado a todos los que forman parte del
                            personal del centro de trabajo o empresa. Este
                            inventario consta de oraciones o proposiciones
                            enumeradas. Lea cada una y decida si, en su caso, es
                            cierta o falsa. Trate de ser SINCERO CONSIGO MISMO y
                            use su propio criterio.
                            <br></br>* CONTESTE LAS PREGUNTAS TAN FRANCA Y
                            HONRADAMENTE COMO LE SEA POSIBLE. No existe ventaja
                            en crear una mejor impresión personal. No marque una
                            respuesta que no sea cierta por creer que "es lo que
                            debe decir". Los resultados de esta prueba NO
                            influirán en nada en su relación con la empresa o
                            centro laboral.
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 8 ? ( // GD
                        <Fragment>
                          {testPersonal ? (
                            <div className={classes.fontParagraph}>
                              <br></br>
                              <b>VALORES INTERPERSONALES (GD)</b>
                              <br></br>
                              GORDON
                              <br></br>
                              <br></br>
                              <b>Señor aspirante a empleado</b>
                              <br></br>
                              <br></br>Este es un cuestionario de sus actitudes,
                              lo que hace o lo que piensa acerca de ciertas
                              situaciones, las personas piensan de una manera u
                              otra.
                              <br></br>
                              Este Cuestionarlo contiene 90 frases que expresan
                              cosas que las personas consideran importantes para
                              su vida; se presentan en 30 tríadas, es decir,
                              agrupadas de tres en tres.
                              <br></br>
                              Su tarea consiste en leer atentamente las tres
                              frases de cada triada y escoger, aunque en algunos
                              casos le sea difícil decidirse, la que indique lo
                              MÁS IMPORTANTE para Ud. dando un "click" con el
                              botón de su "mouse" o "ratón". Observará que una
                              vez que seleccionó su respuesta, al lado derecho
                              de esta aparece la palabra MÁS.
                              <br></br>
                              Luego, de entre las dos restantes frases, debe
                              elegir lo que indique lo MENOS IMPORTANTE para Ud.
                              Aparecerá del lado izquierdo la palabra MENOS.
                              Para pasar a las siguientes opciones de "click" en
                              el botón de continuar.
                              <br></br>
                              Así, pues, en cada tríada quedará una frase sin
                              señalar. Oprima continuar y practique.
                            </div>
                          ) : (
                            <div className={classes.fontParagraph}>
                              <br></br>
                              CONTESTE DE ESTA FORMA LAS SIGUIENTES 30 TRIADAS,
                              SIN PENSARLO DEMASIADO.
                              <br></br>
                              <br></br>
                              No existen contestaciones «buenas» ni «malas»;
                              esto no es una prueba de inteligencia o habilidad,
                              sino simplemente una apreciación de lo que Ud.
                              valora en más y en menos. Trabaje rápidamente y no
                              olvide contestar a todas las triadas. Si tiene
                              dudas en una de ellas, decídase por la respuesta
                              que se acerque más a su modo de ser.
                              <br></br>
                              PROCURE CONTESTAR CON SINCERIDAD.
                              <br></br>
                              Los resultados serán interpretados y utilizados
                              confidencialmente.
                              <br></br>
                              <br></br>
                              <b>AHORA OPRIMA CONTINUAR Y COMIENCE</b>
                            </div>
                          )}
                        </Fragment>
                      ) : props.id.test.id === 10 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>TEST DE INTELIGENCIA NIVEL ALTO (TH)</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>
                            Marque la respuesta correcta dando un "click" con el
                            botón de su "mouse" o "ratón".
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                            <br></br>
                            <br></br>
                            Cada serie tiene un tiempo estipulado de espera.
                            CONTESTE CON LA MAYOR RAPIDEZ POSIBLE.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 13 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>TEST DE COMPATIBILIDAD LABORAL (CL)</b>
                            <br></br>
                            <b>Para análisis general</b>
                            <br></br>
                            <b>Instrucciones:</b>
                            <br></br>
                            <br></br>Te felicitamos por pertenecer al exclusivo
                            grupo que conforma el inventario de ejecutivos. Al
                            contestar el siguiente cuestionario nos permitirá
                            conocer tu perfil de características personales.
                            <br></br>
                            Como sabes, para cada puesto se requieren ciertos
                            rasgos idóneos que para otro pueden no serlo.
                            <br></br>
                            <br></br>
                            Por lo tanto, no hay características buenas ni
                            malas. Se lo más honesto contigo mismo al contestar
                            las siguientes 24 series de 4 palabras, ya que de
                            ello depende obtener tu perfil real.
                            <br></br>
                            <br></br>
                            Observa cuidadosamente las siguientes palabras. Da
                            un click con el botón del mouse a la palabra con que
                            más te identifiques. En seguida da un click a la
                            palabra con que menos te identifiques.
                            <br></br>
                            <br></br>
                            Sobre la palabra con que más te identificas
                            aparecerá la palabra MÁS y bajo la palabra con la
                            que menos te identificas aparecerá la palabra MENOS.
                            Si te has equivocado y quieres rectificar oprime el
                            botón DESHACER.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 14 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>TEST DE INTELIGENCIA (IQ)</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>
                            Marque la respuesta correcta dando un "click" con el
                            botón de su "mouse" o "ratón".
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                            <br></br>
                            <br></br>
                            Cada serie tiene un tiempo estipulado de espera.
                            CONTESTE CON LA MAYOR RAPIDEZ POSIBLE.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 16 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>
                              Test de Adaptabilidad laboral - Nivel Gerencial
                              (MG)
                            </b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>En las siguientes 30 preguntas tiene que
                            responder cuál de las 4 opciones que se presentan
                            corresponde a su manera de actuar.
                            <br></br>Conteste bien a la primera ocasión ya que
                            no podrá regresar a la pregunta anterior una vez que
                            haya seleccionado su respuesta.
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 17 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>Inventario Profesional de Ventas (IPV)</b>
                            <br></br>
                            Para análisis de la personalidad
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>En las pantallas siguientes, encontrará
                            cierto número de situaciones y de preguntas seguidas
                            de varias respuestas posibles. Se le pide que escoja
                            una alternativa para cada pregunta, la que de manera
                            ESPONTÁNEA le parezca preferible.
                            <br></br>Adelante aparecen dos preguntas de ensayo
                            que contestará usted para estar seguro de que
                            comprende lo que debe hacer en el propio
                            cuestionario.
                            <br></br>En algunas ocasiones usted estará
                            personalmente implicado en las preguntas (como en el
                            primer ejemplo), y otras se refieren a personas
                            ajenas (como en el segundo ejemplo).
                            <br></br>
                            <br></br>Observará que una vez que seleccionó su
                            respuesta, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 18 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>ESTILOS DE ADMINISTRACIÓN KOSTICK (KO)</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>Hay 90 pares de frases, Ud. debe escoger de
                            cada par aquella frase que sea más afín con su forma
                            de ser o de pensar. A veces tendrá la impresión de
                            que ninguna refleja esa afinidad o, al contrario,
                            que ambas lo hacen; en todo caso, Ud. debe optar por
                            alguna de las dos. <br></br>
                            Marque la respuesta correcta dando un "click" con el
                            botón de su "mouse" o "ratón". Observará que una vez
                            que seleccionó su respuesta, esta se vuelve de color
                            rojo y después de un segundo pasa a la siguiente
                            pregunta. <br></br>
                            1. CONTESTE LAS PREGUNTAS TAN FRANCA Y HONRADAMENTE
                            COMO LE SEA POSIBLE. No existe ventaja en crear una
                            mejor impresión personal. No marque una respuesta
                            que no sea cierta por creer que "es lo que debe
                            decir". <br></br>
                            2. CONTESTE CON LA MAYOR RAPIDEZ POSIBLE. No dedique
                            tiempo a meditar sobre las preguntas. Anote su
                            primera reacción y pase de allí a la próxima
                            pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 20 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>TEST DE PREFERENCIAS VOCACIONALES (KU)</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>Esta prueba tiene por objeto ayudarle a
                            descubrir sus preferencias vocacionales. No se trata
                            de un examen; aquí no hay respuesta exactas o
                            inexactas. Toda respuesta que refleje fielmente su
                            modo de pensar es una buena respuesta.
                            <br></br>
                            <br></br>Adelante aparecen cinco preguntas de ensayo
                            que contestará usted para estar seguro que comprende
                            lo que debe hacer en el propio cuestionario.
                            <br></br>
                            <br></br>Observará que una vez que seleccionó sus
                            respuestas, se activará el botón "Continuar" para
                            pasar a la siguiente pregunta.
                          </div>
                        </Fragment>
                      ) : props.id.test.id === 21 ? (
                        <Fragment>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>TEST COMPETENCIAS EQ</b>
                            <br></br>
                            <br></br>
                            <b>Señor aspirante</b>
                            <br></br>
                            <br></br>La mayoría de las personas leemos o
                            escuchamos hablar permanentemente sobre la
                            importancia creciente de facultades tales como la
                            motivación, la proactividad, el liderazgo, la
                            cooperación, el compromiso, el optimismo, la
                            adaptabilidad, el autocontrol y la capacidad del
                            trabajo en equipo. La Inteligencia Emocional (IE)
                            justamente concierne a éstas y otras aptitudes
                            íntimamente relacionadas. Muchas personas de gran
                            preparación intelectual, pero faltas de inteligencia
                            emocional, terminan trabajando a las órdenes de
                            personas que tienen un CI menor, pero mayor
                            inteligencia emocional. La Inteligencia Emocional
                            esencialmente proporciona a las personas capacidad y
                            habilidad para regular sus fuerzas e impulsos
                            emocionales contraproducentes, muchos de ellos
                            inconscientes, los cuales boicotean sus relaciones y
                            calidad de vida, por tanto, su desarrollo y progreso
                            en lo educativo, como en la actividad profesional o
                            laboral. Para efectos de este cuestionario por
                            inteligencia emocional se entiende "la capacidad de
                            sentir, entender y aplicar eficazmente el poder y la
                            agudeza de las emociones como fuente de energía
                            humana, información, conexión e influencia." *
                            CONTESTE LAS PREGUNTAS TAN FRANCA Y HONRADAMENTE
                            COMO LE SEA POSIBLE. No existe ventaja en crear una
                            mejor impresión personal. No marque una respuesta
                            que no sea cierta por creer que "es lo que debe
                            decir".
                          </div>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </Grid>

                    {props.id.test.id === 3 ||
                    props.id.test.id === 18 ||
                    props.id.test.id === 21 ? (
                      <Grid item xs={12} sm={12}>
                        <br></br>
                        <center>
                          <Button
                            type="submit"
                            className={classes.buttonLeftSteps2}
                            onClick={handleCloseDialog(true)}
                          >
                            Salir
                          </Button>
                          <Button
                            type="submit"
                            className={classes.buttonSubmit}
                            onClick={handleNext()}
                            endIcon={
                              props.id.status === 2 ? (
                                <ArrowRightAlt />
                              ) : (
                                <WatchLater />
                              )
                            }
                          >
                            {props.id.status === 2 ? 'Continuar' : 'Comenzar'}
                          </Button>
                        </center>
                      </Grid>
                    ) : (
                      <Fragment>
                        <Grid item xs={4} sm={4}></Grid>
                        <Grid item xs={4} sm={4}>
                          <br></br>
                          <center>
                            {testPersonalGender ? (
                              <Fragment>
                                <b>Seleccione su género: </b>
                                <br></br>
                                <LoadGender
                                  callback={getResponseGender}
                                  value={values.genderData}
                                  error=""
                                />
                              </Fragment>
                            ) : (
                              ''
                            )}
                          </center>
                        </Grid>
                        <Grid item xs={4} sm={4}></Grid>
                        <Grid item xs={12} sm={12}>
                          <br></br>
                          <center>
                            <Button
                              type="submit"
                              className={classes.buttonLeftSteps2}
                              onClick={handleCloseDialog(true)}
                            >
                              Salir
                            </Button>
                            {testPersonal ? (
                              <Fragment>
                                {props.id.test.id === 8 ? (
                                  <Button
                                    type="submit"
                                    className={classes.buttonSubmit}
                                    onClick={handleTestPersonalGD()}
                                    endIcon={<ArrowRightAlt />}
                                  >
                                    Continuar
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    className={classes.buttonSubmit}
                                    onClick={handleTestPersonal()}
                                    endIcon={<ArrowRightAlt />}
                                  >
                                    Continuar
                                  </Button>
                                )}
                              </Fragment>
                            ) : (
                              <Button
                                type="submit"
                                className={classes.buttonSubmit}
                                onClick={handleNext()}
                                disabled={!testPersonal ? DisabledStart : false}
                                endIcon={
                                  props.id.status === 2 ? (
                                    <ArrowRightAlt />
                                  ) : (
                                    <WatchLater />
                                  )
                                }
                              >
                                {props.id.status === 2
                                  ? 'Continuar'
                                  : 'Comenzar'}
                              </Button>
                            )}
                          </center>
                        </Grid>
                      </Fragment>
                    )}
                  </Fragment>
                ) : next <= totalPages ? (
                  <Fragment>
                    {props.id.takePhoto ? (
                      <CameraAutoCapture
                        data={props.id}
                        tpmTest={true}
                        userMedia={validUserMedia}
                      />
                    ) : (
                      <></>
                    )}
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      style={{
                        padding: '1px 3px',
                        color: '#333',
                        fontWeight: 'bold',
                        fontSize: 13,
                      }}
                    >
                      <Person
                        style={{ color: '#333', verticalAlign: 'middle' }}
                      />{' '}
                      {user.name}&nbsp;({user.document})
                    </Grid>
                    {rowsSection
                      .slice(values.offset, values.limit)
                      .map((row2, i) => {
                        return (
                          <Fragment>
                            {props.id.test.id === 3 ||
                            props.id.test.id === 6 ||
                            props.id.test.id === 7 ||
                            props.id.test.id === 10 ||
                            props.id.test.id === 14 ||
                            props.id.test.id === 16 ||
                            props.id.test.id === 17 ||
                            props.id.test.id === 20 ? (
                              <Fragment>
                                {testTpm.sections[nextSection].name
                                  .toLowerCase()
                                  .includes('ensayo') ? (
                                  <Fragment>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '5px 30px' }}
                                    >
                                      <div className={classes.fontParagraph}>
                                        <b style={{ fontSize: 18 }}>
                                          {testTpm.sections[nextSection].name}
                                        </b>{' '}
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '5px 30px' }}
                                    >
                                      <div className={classes.fontParagraph}>
                                        <b
                                          style={{
                                            fontSize: 16,
                                            color: 'green',
                                          }}
                                        >
                                          {
                                            testTpm.sections[nextSection]
                                              .scales[nextScale].name
                                          }
                                        </b>{' '}
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '5px 30px' }}
                                    >
                                      <Fragment>
                                        <div className={classes.fontParagraph}>
                                          {props.id.test.id === 10 ? (
                                            testTpm.sections[nextSection]
                                              .name === 'Serie I: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la palabra que
                                                SIGNIFICA LO MISMO, tal como lo
                                                muestra el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie II: Ensayo' ? (
                                              <Fragment>
                                                En la serie que sigue, algunas
                                                figuras son iguales a la
                                                primera, otras son distintas:
                                                <br></br>
                                                <Grid item xs={12} sm={5}>
                                                  <center>
                                                    <img
                                                      src={require('assets/images_tests/th/serie_2/in_op_1.png')}
                                                      alt="demoEx1"
                                                      // style={{
                                                      //   marginTop: 150,
                                                      //   width: 300,
                                                      // }}
                                                    />
                                                  </center>
                                                </Grid>
                                                Las figuras C, E y F son iguales
                                                a la primera, las otras cuando
                                                las enderece quedan así:{' '}
                                                <img
                                                  src={require('assets/images_tests/th/serie_2/in_rs_1.gif')}
                                                  alt="demoEx2"
                                                />
                                                <br></br>
                                                <br></br>
                                                Seleccione las figuras IGUALES A
                                                LA PRIMERA, y deje sin
                                                seleccionar aquellas figuras que
                                                al enderezarlas NO SEAN IGUALES,
                                                tal como lo muestra el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name ===
                                              'Serie III: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción
                                                correspondiente a la{' '}
                                                <b>
                                                  LETRA QUE SIGUE EN LA SERIE
                                                </b>
                                                , tal como lo muestra el
                                                ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie IV: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción <b>Bien</b>{' '}
                                                si la suma ESTA BIEN HECHA y
                                                seleccione <b>Mal</b> si la suma
                                                ESTA MAL HECHA. Si prefiere
                                                dejar la suma puede seleccionar{' '}
                                                <b>Sin contestar</b>, tal como
                                                lo muestra el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie V: Ensayo' ? (
                                              <Fragment>
                                                En esta última sección de la
                                                prueba, encontrarás una serie de
                                                espacios numerados del 1 al 100.
                                                En estos espacios tendrás que
                                                escribir el mayor número posible
                                                de palabras distintas que
                                                comiencen con la letra que se
                                                indicará en la parte superior de
                                                la sección.<br></br>Practica
                                                ahora en los siguientes espacios
                                                para que sepas como moverte ente
                                                ellos. Cuando termines cada
                                                palabra, puedes presionar la
                                                tecla TAB para pasar a la
                                                siguiente ó puedes moverte al
                                                espacio que quieras con un click
                                                del mouse.
                                                <br></br>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={12}
                                                  style={{
                                                    padding: '15px 30px',
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    spacing={1}
                                                    className={
                                                      classes.containerProfile
                                                    }
                                                    style={{
                                                      margin: '15px 0 15px 0',
                                                    }}
                                                  >
                                                    {(() => {
                                                      let rows = []
                                                      for (
                                                        let i = 0;
                                                        i <= 2;
                                                        i++
                                                      ) {
                                                        rows.push(
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                            md={4}
                                                          >
                                                            <FormControl
                                                              style={{
                                                                width: '100%',
                                                              }}
                                                            >
                                                              <TextField
                                                                id={
                                                                  'txtDemoTHTpm' +
                                                                  i +
                                                                  1
                                                                }
                                                                key={
                                                                  'txtDemoTHTpm' +
                                                                  i +
                                                                  1
                                                                }
                                                                autoComplete="off"
                                                                name={
                                                                  'txtDemoTHTpm' +
                                                                  i +
                                                                  1
                                                                }
                                                                onChange={(e) =>
                                                                  handleChangeTH(
                                                                    i,
                                                                    e,
                                                                    true,
                                                                    row2
                                                                  )
                                                                }
                                                                label={i + 1}
                                                                style={{
                                                                  width: '90%',
                                                                }}
                                                                fullWidth
                                                              />
                                                            </FormControl>
                                                          </Grid>
                                                        )
                                                      }
                                                      return rows
                                                    })()}
                                                  </Grid>
                                                </Grid>
                                                Trabaja aprisa y aunque
                                                probablemente no alcances a
                                                terminar, procura escribir el
                                                mayor número de palabras
                                                "DIFERENTES" y no olvides que
                                                todas ellas deben comenzar con
                                                la misma letra que se indica.
                                                <br></br>
                                                <br></br>
                                              </Fragment>
                                            ) : (
                                              ''
                                            )
                                          ) : props.id.test.id === 14 ? (
                                            testTpm.sections[nextSection]
                                              .name === 'Serie I: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción
                                                correspondiente a la palabra que
                                                complete correctamente la
                                                oración, tal como lo muestra el
                                                ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie II: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción
                                                correspondiente a la mejor
                                                respuesta, tal como lo muestra
                                                el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name ===
                                              'Serie III: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción{' '}
                                                <b>IGUAL</b>, cuando las dos
                                                palabras signifiquen lo mismo;
                                                cuando signifiquen lo contrario
                                                seleccione la opción{' '}
                                                <b>OPUESTO</b>, tal como lo
                                                muestra el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie IV: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción que
                                                contenga las letras
                                                correspondientes a las{' '}
                                                <b>DOS</b> palabras que indican
                                                algo que <b>SIEMPRE</b> tiene el
                                                sujeto, tal como lo muestra el
                                                ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie V: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción correcta a
                                                dicho problema de matemáticas,
                                                tal como lo muestra el ejemplo.
                                                Las operaciones que necesite
                                                realizar, hágalas en una hoja en
                                                blanco.
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie VI: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción <b>SÍ</b>,
                                                cuando la respuesta es correcta;
                                                cuando la respuesta es
                                                incorrecta seleccione la opción{' '}
                                                <b>NO</b>, tal como lo muestra
                                                el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name ===
                                              'Serie VII: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción
                                                correspondiente a la palabra que
                                                complemente correctamente la
                                                frase, tal como lo muestra el
                                                ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name ===
                                              'Serie VIII: Ensayo' ? (
                                              <Fragment>
                                                Las palabras de cada una de las
                                                oraciones siguientes están
                                                mezcladas. Ordene mentalmente
                                                cada una de las oraciones. Si el
                                                significado de la oración es
                                                verdadero, seleccione{' '}
                                                <b>VERDADERO</b>, si el
                                                significado de la oración es
                                                falso, seleccione <b>FALSO</b>,
                                                tal como lo muestra el ejemplo:
                                              </Fragment>
                                            ) : testTpm.sections[nextSection]
                                                .name === 'Serie IX: Ensayo' ? (
                                              <Fragment>
                                                Seleccione la opción que{' '}
                                                <b>NO</b> corresponde con las
                                                demás palabras, tal como lo
                                                muestra el ejemplo:
                                              </Fragment>
                                            ) : (
                                              <Fragment>
                                                En cada pregunta procure
                                                encontrar como están hechas las
                                                series, después, seleccione la
                                                respuesta que contengan los{' '}
                                                <b>DOS</b> números que deben
                                                seguir en cada serie.
                                              </Fragment>
                                            )
                                          ) : props.id.test.id === 20 ? (
                                            <Fragment>
                                              Marque la respuesta QUE LE GUSTE
                                              MÁS, observará que una vez que
                                              seleccionó su respuesta, abajo
                                              aparece la palabra <b>MÁS</b>.
                                              Ahora seleccione igualmente la QUE
                                              LE GUSTE MENOS, abajo aparece la
                                              palabra <b>MENOS</b>.
                                            </Fragment>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </Fragment>

                                      <Fragment>
                                        {testTpm.sections[nextSection]
                                          .guidelines !== 'guidelinesth8' &&
                                        testTpm.sections[nextSection]
                                          .guidelines !== 'guidelinesth9' ? (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            style={{ padding: '15px 30px' }}
                                          >
                                            <Animated
                                              animationIn="fadeIn"
                                              animationInDuration={1000}
                                              isVisible={true}
                                            >
                                              <div
                                                className={
                                                  classes.fontParagraph
                                                }
                                              >
                                                <b style={{ fontSize: 16 }}>
                                                  {row2.statement}
                                                </b>
                                              </div>
                                              <div
                                                className={
                                                  classes.fontParagraph
                                                }
                                              >
                                                {row2.image ? (
                                                  <img
                                                    src={require('assets/images_tests/th/serie_2/' +
                                                      row2.image)}
                                                    alt="question-img"
                                                  ></img>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                              {row2.answers.map((row2x) => {
                                                return (
                                                  <Fragment>
                                                    <FormControl
                                                      component="fieldset"
                                                      style={{
                                                        margin:
                                                          '1px 0px 0px 35px',
                                                        width: '100%',
                                                      }}
                                                    >
                                                      {
                                                        // props.id.test.id === 8 || // GD
                                                        // props.id.test.id === 10 || // TH
                                                        // props.id.test.id === 13 || // CL
                                                        // props.id.test.id === 20 ? ( // KU-V
                                                        row2.questiontypeId ===
                                                        1 ? (
                                                          <FormControlLabel
                                                            value={
                                                              row2x.rightAnswer
                                                            }
                                                            control={
                                                              <GreenRadio />
                                                            }
                                                            label={
                                                              row2x.statement
                                                            }
                                                            style={{
                                                              color: 'dimgray',
                                                            }}
                                                            checked={
                                                              row2x.newvalue !==
                                                              undefined
                                                                ? row2x.newvalue
                                                                : false
                                                            }
                                                            onClick={(
                                                              event
                                                            ) => {
                                                              handleRadioChange(
                                                                event,
                                                                row2,
                                                                row2x
                                                              )
                                                            }}
                                                          />
                                                        ) : (
                                                          <Fragment>
                                                            <FormControlLabel
                                                              control={
                                                                <GreenCheckbox
                                                                  onClick={(
                                                                    event
                                                                  ) => {
                                                                    handleRadioChange(
                                                                      event,
                                                                      row2,
                                                                      row2x
                                                                    )
                                                                  }}
                                                                  disabled={
                                                                    row2x.checkedDisabled ===
                                                                    undefined
                                                                      ? false
                                                                      : row2x.checkedDisabled
                                                                  }
                                                                  checked={
                                                                    row2x.valueDefault ===
                                                                    undefined
                                                                      ? false
                                                                      : row2x.valueDefault
                                                                  }
                                                                  name={
                                                                    row2x.statement
                                                                  }
                                                                />
                                                              }
                                                              label={
                                                                row2x.statement
                                                              }
                                                              style={{
                                                                color:
                                                                  'dimgray',
                                                              }}
                                                            />
                                                            <b>
                                                              {row2x.valueText}
                                                            </b>
                                                          </Fragment>
                                                        )
                                                      }
                                                    </FormControl>
                                                  </Fragment>
                                                )
                                              })}
                                            </Animated>
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                        {next < totalPages ? (
                                          <Fragment>
                                            <b>
                                              Seleccione la(s) respuesta(s) para
                                              continuar. UNA VEZ FINALIZADAS
                                              ESTAS PREGUNTAS DE ENSAYO INICIARÁ
                                              EL TEST
                                            </b>
                                          </Fragment>
                                        ) : (
                                          <Fragment>
                                            <div
                                              className={classes.fontParagraph}
                                            >
                                              {testTpm.sections[nextSection + 1]
                                                .time > 0 ? (
                                                <Fragment>
                                                  La siguiente sección dura
                                                  exactamente{' '}
                                                  <b>
                                                    {
                                                      testTpm.sections[
                                                        nextSection + 1
                                                      ].time
                                                    }{' '}
                                                    minutos
                                                  </b>
                                                  , una vez transcurrido este
                                                  tiempo, pasará AUTOMÁTICAMENTE
                                                  a una pantalla como esta para
                                                  su siguiente prueba. No podrá
                                                  regresar a contestar esta
                                                  sección una vez transcurrido
                                                  el tiempo.
                                                  <br></br>
                                                  <br></br>
                                                </Fragment>
                                              ) : (
                                                ''
                                              )}
                                              <b>NO OPRIMA</b> el botón de
                                              INICIAR hasta que haya entendido
                                              bien las instrucciones. Cuando ya
                                              esté seguro oprima con el botón
                                              del MOUSE en el botón de INICIAR.
                                            </div>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    </Grid>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '5px 30px' }}
                                    >
                                      <div className={classes.fontParagraph}>
                                        <b style={{ fontSize: 18 }}>
                                          {testTpm.sections[nextSection].name}
                                        </b>{' '}
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '5px 30px' }}
                                    >
                                      <div className={classes.fontParagraph}>
                                        <b
                                          style={{
                                            fontSize: 16,
                                            color: 'green',
                                          }}
                                        >
                                          {
                                            testTpm.sections[nextSection]
                                              .scales[nextScale].name
                                          }
                                        </b>{' '}
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '5px 30px' }}
                                    >
                                      <div className={classes.fontParagraph}>
                                        {props.id.test.id === 3 ? (
                                          testTpm.sections[nextSection].scales[
                                            nextScale
                                          ].guidelines ===
                                          'guidelinesescalaeq1' ? (
                                            <Fragment>
                                              Piense en el año pasado. Indique
                                              cuanta aflicción le causó el
                                              siguiente suceso:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq2' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              cuanta aflicción le causó la
                                              siguiente presión del trabajo:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq3' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              cuanta aflicción le causó la
                                              siguiente presión personal:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq4' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación la manera como usted
                                              piensa y siente sobre usted:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq5' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación la manera como usted
                                              piensa y siente sobre usted:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq6' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación la manera como usted
                                              piensa y siente sobre usted:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq7' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq8' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq9' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq10' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq11' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq12' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq13' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq14' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq15' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq16' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq17' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación su conducta o
                                              intención:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq18sf' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              con que frecuencia ha presentado
                                              el siguiente síntoma:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq18sc' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              con que frecuencia ha presentado
                                              el siguiente síntoma:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq18se' ? (
                                            <Fragment>
                                              Piense en el mes pasado. Indique
                                              con que frecuencia ha presentado
                                              la siguiente conducta:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq19' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación la manera como usted
                                              piensa y siente sobre usted:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq20' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación la manera como usted
                                              piensa y siente sobre usted:
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalaeq21' ? (
                                            <Fragment>
                                              Indique como describe la siguiente
                                              afirmación la manera como usted
                                              piensa y siente sobre usted:
                                            </Fragment>
                                          ) : (
                                            ''
                                          )
                                        ) : props.id.test.id === 10 ? (
                                          testTpm.sections[nextSection].scales[
                                            nextScale
                                          ].guidelines ===
                                          'guidelinesescalath1' ? (
                                            <Fragment>
                                              Seleccione la palabra que
                                              SIGNIFICA LO MISMO.
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalath3' ? (
                                            <Fragment>
                                              Seleccione las figuras IGUALES A
                                              LA PRIMERA, y deje sin seleccionar
                                              aquellas figuras que al
                                              enderezarlas NO SEAN IGUALES.
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalath5' ? (
                                            <Fragment>
                                              Seleccione la opción
                                              correspondiente a la{' '}
                                              <b>LETRA QUE SIGUE EN LA SERIE</b>
                                              . .
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalath7' ? (
                                            <Fragment>
                                              Seleccione la opción <b>Bien</b>{' '}
                                              si la suma ESTA BIEN HECHA y
                                              seleccione <b>Mal</b> si la suma
                                              ESTA MAL HECHA. Si prefiere dejar
                                              la suma puede seleccionar{' '}
                                              <b>Sin contestar</b>.
                                            </Fragment>
                                          ) : testTpm.sections[nextSection]
                                              .scales[nextScale].guidelines ===
                                            'guidelinesescalath9' ? (
                                            <Fragment>
                                              En estos espacios tendrás que
                                              escribir el mayor número posible
                                              de palabras distintas que
                                              comiencen con la letra <b>C</b>.
                                            </Fragment>
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ padding: '15px 30px' }}
                                    >
                                      <Animated
                                        animationIn="fadeIn"
                                        animationInDuration={1000}
                                        isVisible={true}
                                      >
                                        <div className={classes.fontParagraph}>
                                          <b style={{ fontSize: 16 }}>
                                            {row2.statement}
                                          </b>
                                        </div>
                                        <div className={classes.fontParagraph}>
                                          {row2.image ? (
                                            <img
                                              src={require('assets/images_tests/th/serie_2/' +
                                                row2.image)}
                                              alt="question-img"
                                            ></img>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                        {props.id.test.id === 10 &&
                                        testTpm.sections[nextSection]
                                          .guidelines === 'guidelinesth9' ? (
                                          <Grid
                                            container
                                            spacing={1}
                                            className={classes.containerProfile}
                                            style={{
                                              margin: '15px 0 15px 0',
                                            }}
                                          >
                                            {(() => {
                                              let rows = []
                                              for (let i = 0; i <= 99; i++) {
                                                rows.push(
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    sm={4}
                                                    md={3}
                                                  >
                                                    <FormControl
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                    >
                                                      <TextField
                                                        id={'txtTHTpm' + i + 1}
                                                        key={'txtTHTpm' + i + 1}
                                                        autoComplete="off"
                                                        name={
                                                          'txtTHTpm' + i + 1
                                                        }
                                                        onChange={(e) =>
                                                          handleChangeTH(
                                                            i,
                                                            e,
                                                            false,
                                                            row2
                                                          )
                                                        }
                                                        label={i + 1}
                                                        error={
                                                          values.errorEmail
                                                        }
                                                        style={{
                                                          width: '90%',
                                                        }}
                                                        fullWidth
                                                      />
                                                    </FormControl>
                                                  </Grid>
                                                )
                                              }
                                              return rows
                                            })()}
                                          </Grid>
                                        ) : (
                                          row2.answers.map((row2x) => {
                                            return (
                                              <Fragment>
                                                <FormControl
                                                  component="fieldset"
                                                  style={{
                                                    margin: '1px 0px 0px 35px',
                                                    width: '100%',
                                                  }}
                                                >
                                                  {
                                                    // props.id.test.id === 8 || // GD
                                                    // props.id.test.id === 13 || // CL
                                                    // props.id.test.id === 20 ? ( // KU-V
                                                    row2.questiontypeId ===
                                                    1 ? (
                                                      <FormControlLabel
                                                        value={
                                                          row2x.rightAnswer
                                                        }
                                                        control={<GreenRadio />}
                                                        label={row2x.statement}
                                                        style={{
                                                          color: 'dimgray',
                                                        }}
                                                        checked={
                                                          row2x.newvalue !==
                                                          undefined
                                                            ? row2x.newvalue
                                                            : false
                                                        }
                                                        onClick={(event) => {
                                                          handleRadioChange(
                                                            event,
                                                            row2,
                                                            row2x
                                                          )
                                                        }}
                                                      />
                                                    ) : (
                                                      <Fragment>
                                                        <FormControlLabel
                                                          control={
                                                            <GreenCheckbox
                                                              onClick={(
                                                                event
                                                              ) => {
                                                                handleRadioChange(
                                                                  event,
                                                                  row2,
                                                                  row2x
                                                                )
                                                              }}
                                                              disabled={
                                                                row2x.checkedDisabled ===
                                                                undefined
                                                                  ? false
                                                                  : row2x.checkedDisabled
                                                              }
                                                              checked={
                                                                row2x.valueDefault ===
                                                                undefined
                                                                  ? false
                                                                  : row2x.valueDefault
                                                              }
                                                              name={
                                                                row2x.statement
                                                              }
                                                            />
                                                          }
                                                          label={
                                                            row2x.statement
                                                          }
                                                          style={{
                                                            color: 'dimgray',
                                                          }}
                                                        />
                                                        <b>{row2x.valueText}</b>
                                                      </Fragment>
                                                    )
                                                  }{' '}
                                                </FormControl>
                                              </Fragment>
                                            )
                                          })
                                        )}
                                      </Animated>
                                    </Grid>
                                  </Fragment>
                                )}
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{ padding: '5px 30px' }}
                                >
                                  <div className={classes.fontParagraph}>
                                    &nbsp;&nbsp;&nbsp;
                                    <b style={{ fontSize: 13 }}>
                                      {testTpm.sections[nextSection].name}
                                    </b>{' '}
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{ padding: '5px 30px' }}
                                >
                                  <div className={classes.fontParagraph}>
                                    &nbsp;&nbsp;&nbsp;
                                    <b style={{ fontSize: 16, color: 'green' }}>
                                      {row2.nameScale}
                                    </b>{' '}
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{ padding: '15px 30px' }}
                                >
                                  <Animated
                                    animationIn="fadeIn"
                                    animationInDuration={1000}
                                    isVisible={true}
                                  >
                                    {row2.statement !== '----------' ? (
                                      <div className={classes.fontParagraph}>
                                        &nbsp;&nbsp;&nbsp;
                                        <b style={{ fontSize: 16 }}>
                                          {row2.order + ') ' + row2.statement}
                                        </b>
                                      </div>
                                    ) : (
                                      <div className={classes.fontParagraph}>
                                        &nbsp;&nbsp;&nbsp;
                                        <b style={{ fontSize: 16 }}>
                                          {'Nº ' + row2.order}
                                        </b>
                                      </div>
                                    )}

                                    <br></br>
                                    {row2.answers.map((row2x) => {
                                      return (
                                        <Fragment>
                                          <FormControl
                                            component="fieldset"
                                            style={{
                                              margin: '1px 0px 0px 35px',
                                              width: '100%',
                                            }}
                                          >
                                            {props.id.test.id !== 8 &&
                                            props.id.test.id !== 13 ? ( // ! GD | CL
                                              <FormControlLabel
                                                value={row2x.rightAnswer}
                                                control={<GreenRadio />}
                                                label={row2x.statement}
                                                style={{ color: 'dimgray' }}
                                                checked={
                                                  row2x.newvalue !== undefined
                                                    ? row2x.newvalue
                                                    : false
                                                }
                                                onClick={(event) => {
                                                  handleRadioChange(
                                                    event,
                                                    row2,
                                                    row2x
                                                  )
                                                }}
                                              />
                                            ) : (
                                              <Fragment>
                                                <FormControlLabel
                                                  control={
                                                    <GreenCheckbox
                                                      onClick={(event) => {
                                                        handleRadioChange(
                                                          event,
                                                          row2,
                                                          row2x
                                                        )
                                                      }}
                                                      disabled={
                                                        row2x.checkedDisabled ===
                                                        undefined
                                                          ? false
                                                          : row2x.checkedDisabled
                                                      }
                                                      checked={
                                                        row2x.valueDefault ===
                                                        undefined
                                                          ? false
                                                          : row2x.valueDefault
                                                      }
                                                      name={row2x.statement}
                                                    />
                                                  }
                                                  label={row2x.statement}
                                                  style={{ color: 'dimgray' }}
                                                />
                                                <b>{row2x.valueText}</b>
                                              </Fragment>
                                            )}{' '}
                                          </FormControl>
                                        </Fragment>
                                      )
                                    })}
                                  </Animated>
                                </Grid>
                              </Fragment>
                            )}
                          </Fragment>
                        )
                      })}

                    <Grid xs={12} md={12} style={{ marginTop: 30 }}>
                      <center>
                        {/*<Button
                        type="submit"
                        className={classes.buttonLeftSteps}
                        // onClick={cancelSteps}
                      >
                        Deshacer
                      </Button>*/}
                        {(() => {
                          if (
                            next < totalPages ||
                            testTpm.sections[nextSection].scales[
                              nextScale + 1
                            ] ||
                            testTpm.sections[nextSection + 1]
                          ) {
                            return (
                              <Fragment>
                                {/*<Button
                              type="submit"
                              className={classes.buttonLeftSteps2}
                              onClick={handleBack()}
                              startIcon={<Replay />}
                              disabled={disabledBack}
                            >
                              Regresar al inicio
                            </Button>*/}

                                {props.id.test.id === 8 ||
                                props.id.test.id === 13 ||
                                props.id.test.id === 20 ? (
                                  <Button
                                    type="submit"
                                    className={classes.buttonLeftSteps2}
                                    onClick={handleDeleteSelectGD()}
                                    startIcon={<Replay />}
                                    disabled={disabledDeleteSelectGD}
                                  >
                                    Deshacer
                                  </Button>
                                ) : (
                                  ''
                                )}

                                <Button
                                  type="submit"
                                  className={classes.buttonSubmit}
                                  onClick={handleNext()}
                                  endIcon={<ArrowRightAlt />}
                                  disabled={
                                    props.id.test.id === 10 &&
                                    testTpm.sections[nextSection].guidelines ===
                                      'guidelinesth8'
                                      ? false
                                      : disabledNext
                                  }
                                >
                                  {next < totalPages ||
                                  !testTpm.sections[nextSection].name
                                    .toLowerCase()
                                    .includes('ensayo')
                                    ? 'Continuar'
                                    : 'Iniciar'}
                                </Button>
                              </Fragment>
                            )
                          }

                          return (
                            <Fragment>
                              {/*  <Button
                              type="submit"
                              className={classes.buttonLeftSteps2}
                              onClick={handleBack()}
                              startIcon={<Replay />}
                              //disabled={disabledNext}
                            >
                              Regresar al inicio
                            </Button>*/}
                              <Button
                                type="submit"
                                className={classes.buttonSubmit}
                                onClick={handleSaved()}
                                //onClick={handleNext()}
                                endIcon={<Save />}
                                disabled={
                                  props.id.test.id === 10 &&
                                  testTpm.sections[nextSection].guidelines ===
                                    'guidelinesth9'
                                    ? false
                                    : disabledNext
                                }
                              >
                                Finalizar
                              </Button>
                            </Fragment>
                          )
                        })()}
                      </center>
                    </Grid>
                  </Fragment>
                ) : (
                  <Grid item xs={12} sm={12} style={{ padding: 20 }}>
                    <center>
                      <img
                        src={successImg}
                        alt="successPassword"
                        style={{
                          width: '150px',
                        }}
                      />
                      <br></br>
                      <Animated
                        animationIn="bounceIn"
                        animationInDuration={1000}
                        isVisible={true}
                      >
                        <div className={classes.titleCard}>
                          Usted ha finalizado el test de forma exitosa.
                        </div>
                      </Animated>
                    </center>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={() => {
                          props.callBackRefreshPsychometrics()
                          props.exit()
                        }}
                      >
                        Aceptar
                      </Button>
                    </center>
                  </Grid>
                )}
              </Grid>
            )}
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ paddingRight: 35, marginLeft: 15 }}
          >
            {next <= totalPages && next > 0 ? (
              <Fragment>
                <div style={{ float: 'left' }}>
                  <Button
                    type="submit"
                    className={classes.buttonLeftSteps2}
                    onClick={handleExit(true)}
                  >
                    Salir
                  </Button>
                </div>
                {!testTpm.sections[nextSection].name
                  .toLowerCase()
                  .includes('ensayo') ? (
                  <Fragment>
                    <div style={{ float: 'right' }}>
                      {!timer0 ? (
                        <span className={classes.cardTitleDialog}>
                          <WatchLater
                            fontSize="small"
                            className={classes.iconTheme}
                          />{' '}
                          <small style={{ color: colorTimer }}>
                            {(hrs < 10 ? '0' + hrs : hrs) +
                              ':' +
                              (mins < 10 ? '0' + mins : mins) +
                              ':' +
                              (secs < 10 ? '0' + secs : secs)}
                          </small>
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </Fragment>
                ) : (
                  ''
                )}
              </Fragment>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
