import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'
import Slide from '@material-ui/core/Slide'
import { Cancel, SaveAlt, Save } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Papa from 'papaparse'
import Moment from 'moment'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import ViewProfilePrivileges from 'views/dialog/ViewProfilePrivilegesUser'
import LoadGender from 'components/services/LoadGender'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  }
}

export default function NewApplicants(props) {
  const classes = useStyles()
  const theme = useTheme()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorServer, setErrorServer] = useState(false)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [privilegesResources, setPrivilegesResources] = useState([28, 30])
  const [disabled, setDisabled] = useState(false)
  const [OpenEnable, setOpenEnable] = useState(false)
  const [ProfileID, setProfileID] = useState('')
  const [valueTabs, setValueTabs] = React.useState(0)
  const [disabledMasive, setDisabledMasive] = useState(true)
  const [rowsImport, setRowsImport] = useState([])
  const [rows, setRows] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    email: '',
    document_id: '',
    name: '',
    lastName: '',
    gender: '',
    genderData: '',
    birthdate: '',
    errorEmail: false,
    errorDocument_id: false,
    errorLastName: false,
    errorName: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorEmail: false,
      errorDocument_id: false,
      errorLastName: false,
      errorName: false,
    })
    setOpenAlert(false)
  }

  const handleChangeDNI = (event) => {
    setValues({
      ...values,
      document_id: event.target.value,
      errorDocument_id: false,
    })
    setOpenAlert(false)

    DataListApplicant(event.target.value)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setOpenAlert(false)
    setErrorServer(false)

    if (valueTabs === 0) {
      if (rows.length === 0) {
        if (values.name.length === 0) {
          setValues({ ...values, errorName: true })
          setMensaje('El campo "Nombre" es obligatorio.')
          setOpenAlert(true)
          setError('error')
        } else if (values.lastName.length === 0) {
          setValues({ ...values, errorLastName: true })
          setMensaje('El campo "Apellidos" es obligatorio.')
          setOpenAlert(true)
          setError('error')
        } else if (values.document_id.length === 0) {
          setValues({ ...values, errorDocument_id: true })
          setMensaje('El campo "Documento" es obligatorio.')
          setOpenAlert(true)
          setError('error')
        } /* else if (values.email.length === 0) {
        setValues({ ...values, errorEmail: true });
        setMensaje('El campo "Correo electrónico" es obligatorio.');
        setOpenAlert(true);
        setError("error");
      }*/ else {
          setoOpenBackdrop(true)

          const dataValue = {
            documentId: values.document_id,
            name: values.name,
            lastName: values.lastName,
            email: values.email !== '' ? values.email.toLowerCase().trim() : '',
            profileId: 6,
            accountId: localStorage.getItem('account_id'),
          }

          if (values.birthdate !== '') {
            dataValue.birthdate = values.birthdate
          }

          if (values.gender !== '') {
            dataValue.genderId = values.gender
          }

          axios
            .post(`${urlServices}users`, dataValue, {
              headers: {
                application: keyAplication,
                Authorization: 'Bearer ' + keyAuthorization,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                setoOpenBackdrop(false)

                setValues({
                  email: '',
                  name: '',
                  lastName: '',
                  document_id: '',
                  gender: '',
                  genderData: '',
                  birthdate: '',
                })

                setOpenAlert(true)
                setMensaje('Registro guardado con éxito.')
                setError('success')
                SaveProfileResources(response.data.user.id)

                props.callBackRefresh()
              }
            })
            .catch((e) => {
              //console.log(e)
              if (e.response.status === 401) {
                setoOpenBackdrop(false)
                setTimeout(() => {
                  localStorage.clear()
                  setReturnLogin(true)
                }, 200)
              } else {
                setoOpenBackdrop(false)

                setMensaje('Error de conexión, intente más tarde.')
                setOpenAlert(true)
                setError('error')
                setErrorServer(true)
              }
            })
        }
      } else {
        setMensaje('La cédula ingresada ya se encuentra registrada.')
        setValues({ ...values, errorDocument_id: true })
        setOpenAlert(true)
        setError('error')
        //setRows([])
      }
    } else if (valueTabs === 1) {
      if (rows.length === 0) {
        let dataApplicants = rowsImport.length,
          n = 0

        if (dataApplicants > 0) {
          setoOpenBackdrop(true)
          rowsImport.forEach((row1) => {
            const insert = () => {
              axios
                .post(`${urlServices}users`, row1.value, {
                  headers: {
                    application: keyAplication,
                    Authorization: 'Bearer ' + keyAuthorization,
                  },
                })
                .then(
                  (response) => {
                    n++
                    if (n === dataApplicants) {
                      setOpenAlert(true)
                      setMensaje('Datos registrados con éxito.')

                      setRowsImport([])
                      setValueTabs(0)
                      setDisabledMasive(true)

                      props.callBackRefresh()
                      setoOpenBackdrop(false)
                    }
                  },
                  (error) => {
                    setErrorServer(true)
                    setoOpenBackdrop(false)
                  }
                )
                .catch((e) => {
                  if (e.response.status === 401) {
                    setTimeout(() => {
                      localStorage.clear()
                      setReturnLogin(true)
                    }, 200)
                  } else {
                    console.log(e)
                  }
                })
            }
            insert()
          })
        }
      } else {
        setDisabledMasive(true)
        setMensaje(
          'Ya hay cédulas registradas en el sistema, eliminela del documento.'
        )
        setOpenAlert(true)
        setError('error')
      }
    }
  }

  const arrayDNY = (data) => {
    if (data.length > 0) {
      return (
        <Fragment>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow style={{ backgroundColor: '#F2F2F2' }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    ELIMINE DEL DOCUMENTO ESTAS CÉDULAS
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: 'tomato' }}>
                  <TableCell
                    colSpan={9}
                    style={{
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    ESTAS CÉDULAS YA ESTÁN REGISTRADAS CON ESTOS USUARIOS EN EL
                    SISTEMA
                  </TableCell>
                </TableRow>
                <TableRow className={classes.titleFilterAction}>
                  <TableCell
                    style={{
                      fontWeight: '600',
                      textAlign: 'center',
                      fontSize: 14,
                      backgroundColor: '#F2F2F2',
                    }}
                  >
                    Cédula
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: '600',
                      textAlign: 'center',
                      fontSize: 14,
                      backgroundColor: '#F2F2F2',
                    }}
                  >
                    Nombres y apellidos
                  </TableCell>
                </TableRow>
                {rows.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        style={{
                          textAlign: 'center',
                          fontSize: 12,
                          color: 'red',
                        }}
                      >
                        {row.documentId}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'center',
                          fontSize: 12,
                          color: 'red',
                        }}
                      >
                        {row.name + ' ' + row.lastName}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )
    }
  }

  const DataListApplicant = (documentId) => {
    const search = async () => {
      await axios
        .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let result = response.data.users.filter(
              (filtered) =>
                filtered.documentId === documentId &&
                Number(filtered.profileId) === 6
            )

            if (result.length > 0) {
              rows.push(result[0])
            } else {
              setRows([])
            }
          }
        })
        .catch((e) => {
          //console.log(e)
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setErrorServer(true)
          }
        })
    }

    search()
  }

  const SaveProfileResources = (id) => {
    if (privilegesResources.length > 0) {
      privilegesResources.forEach((row) => {
        const dataValue3 = {
          userId: id,
          resourceId: row,
        }

        axios
          .post(`${urlServices}users-resources`, dataValue3, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {
            //console.log(response.data)
            return
          })
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              console.log(e)
            }
          })
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const callBackPrivileges = (data) => {
    setPrivilegesResources(data)
    setDisabled(false)
    setOpenEnable(false)
  }

  // const openEnable = () => {
  //   setProfileID('6')
  //   setOpenEnable(true)
  // }

  const handleDateChange = (e) => {
    setValues({
      ...values,
      birthdate: e.target.value,
    })
  }

  const getResponseGender = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        gender: pro.id,
        genderData: pro,
      })
    } else {
      setValues({
        ...values,
        gender: '',
        genderData: pro,
      })
    }
  }

  const handleChangeIndex = (index) => {
    setValueTabs(index)
  }

  const handleChangeTabs = (event, newValue) => {
    setValues({
      email: '',
      name: '',
      lastName: '',
      document_id: '',
      gender: '',
      genderData: '',
      birthdate: '',
    })

    setValueTabs(newValue)
  }

  const changeHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = []
          const valuesArray = []
          const out = []

          // Iterating data to get column name and their values
          results.data.forEach((d) => {
            rowsArray.push(Object.keys(d))
            valuesArray.push(Object.values(d))
          })

          // Parsed Data Response in array format
          // setParsedData(results.data);

          // Filtered Column Names
          // setTableRows(rowsArray[0]);

          // Filtered Values
          // setValues(valuesArray);

          results.data.forEach((row1) => {
            /*genderId: row1.genero,
            birthdate: row1.fecha_nacimiento*/

            DataListApplicant(row1.cedula)

            let value = {
              documentId: row1.cedula.trim(),
              name: row1.nombres,
              lastName: row1.apellidos,
              email: row1.correo !== '' ? row1.correo.toLowerCase().trim() : '',
              profileId: '6',
              admin: '0',
              accountId: localStorage.getItem('account_id'),
            }

            if (row1.genero !== '') {
              value.genderId = row1.genero.toUpperCase() === 'MASCULINO' ? 1 : 2
            }

            if (row1.fecha_nacimiento !== '') {
              if (row1.fecha_nacimiento !== 'Invalid date') {
                value.birthdate = Moment(row1.fecha_nacimiento).format(
                  'YYYY-MM-DD'
                )
              }
            }

            out.push({ value })
          })

          setRowsImport(out)
          setDisabledMasive(false)
        },
      })
    }
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Nuevo candidato</div>
                <div className={classes.lineBottom}></div>
                <div className={classes.titleCardFilter}>
                  {' '}
                  <small>
                    Al registrar esta cuenta con correo electrónico, se le
                    enviará al correo una clave temporal para el inicio de
                    sesión. (Revisar tanto la bandeja de entrada como la del
                    spam).
                  </small>
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="action tabs example"
                  >
                    <Tab
                      label="Carga manual"
                      {...a11yProps(0)}
                      style={{ color: '#000' }}
                    />
                    <Tab
                      label="Carga masiva"
                      {...a11yProps(1)}
                      style={{ color: '#000' }}
                    />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={valueTabs}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={valueTabs} index={0} dir={theme.direction}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        backgroundColor: '#f8f8f8',
                        borderRadius: 5,
                        padding: 10,
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <NumberFormat
                          customInput={TextField}
                          name="document_id"
                          value={values.document_id}
                          onChange={(e) => handleChangeDNI(e)}
                          //prefix={"$"}
                          // thousandSeparator="."
                          //  decimalSeparator=","
                          allowNegative={false}
                          inputProps={{
                            style: {
                              textAlign: 'center',
                            },
                          }}
                          label="Documento"
                          autoComplete="off"
                          margin="dense"
                          fullWidth
                          required
                          helperText="Cédula, RUC o pasaporte."
                          error={values.errorDocument_id}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="off"
                          name="name"
                          value={values.name}
                          onChange={handleChange('name')}
                          label="Nombres"
                          error={values.errorName}
                          margin="dense"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="off"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange('lastName')}
                          label="Apellidos"
                          error={values.errorLastName}
                          margin="dense"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="off"
                          name="email"
                          value={values.email}
                          onChange={handleChange('email')}
                          label="Correo electrónico"
                          error={values.errorEmail}
                          margin="dense"
                          fullWidth
                          //  required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LoadGender
                          callback={getResponseGender}
                          value={values.genderData}
                          error=""
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="date"
                          label="Fecha de nacimiento"
                          type="date"
                          defaultValue={''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => handleDateChange(e)}
                          margin="dense"
                          variant="outlined"
                          autoComplete="off"
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                        <br></br>
                        <div className={classes.cardTitle}>
                          {" "}
                          <Apps className={classes.iconFilter} /> Privilegios
                          que tiene el perfil &nbsp;&nbsp;
                          <Button
                            className={classes.btnGreen}
                            onClick={() => openEnable()}
                          >
                            Privilegios
                          </Button>
                        </div>
                      </Grid>*/}
                      <Grid item xs={12} sm={12}>
                        {errorServer ? (
                          <Alert severity="error">
                            Error en la consulta al servidor
                          </Alert>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <br></br>
                        <center>
                          <Button
                            type="submit"
                            className={classes.buttonSubmit}
                            onClick={submitForm}
                            disabled={disabled}
                          >
                            Guardar
                          </Button>
                        </center>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={valueTabs} index={1} dir={theme.direction}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        backgroundColor: '#f8f8f8',
                        borderRadius: 5,
                        padding: 10,
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Alert severity="warning">
                          <AlertTitle>IMPORTANTE</AlertTitle>- La columna&nbsp;
                          <strong>Fecha de nacimiento</strong> no es
                          obligatorio, pero si desea agregar una fecha es
                          importante mantener este formato ej:{' '}
                          <strong>2000-10-20</strong> seleccionando formato de
                          celdas "Fecha" en el excel.
                          <br></br> - La columna <strong>Género</strong> no es
                          obligatorio, pero si desea agregar un género es
                          importante mantener este formato ej:{' '}
                          <strong>MASCULINO o FEMENINO</strong>. <br></br>- Al
                          finalizar con el archivo excel, deberá ir a{' '}
                          <strong>"ARCHIVO y GUARDAR COMO"</strong>, seleccionar
                          el formato del archivo tipo&nbsp;
                          <strong>
                            "(CSV CODIFICADO EN UTF-8 (DELIMITADO POR COMAS))"{' '}
                          </strong>
                          y <strong>"GUARDAR"</strong>.
                        </Alert>
                      </Grid>
                      {rows.length === 0 ? (
                        <Fragment>
                          <Grid item xs={12} md={12}>
                            <center>
                              <input
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={changeHandler}
                                id="contained-button-file"
                                multiple
                                type="file"
                                name="filecsv"
                              />
                              <a
                                href={'/documents/csvcandidatos.csv'}
                                download="csvcandidatos.csv"
                              >
                                <Button
                                  className={classes.buttonLeftSteps2}
                                  startIcon={<SaveAlt />}
                                >
                                  Descargar formato
                                </Button>
                              </a>
                              <label htmlFor="contained-button-file">
                                <Button
                                  className={classes.buttonContactGreen}
                                  component="span"
                                >
                                  Subir documento
                                </Button>
                              </label>
                              <br></br>
                              <br></br>
                              <Button
                                className={classes.buttonSubmit2}
                                onClick={submitForm}
                                disabled={disabledMasive}
                                startIcon={<Save />}
                              >
                                Guardar
                              </Button>
                            </center>
                          </Grid>
                        </Fragment>
                      ) : (
                        ''
                      )}
                      <Grid item xs={12} md={12} style={{ marginTop: '3%' }}>
                        {arrayDNY(rows)}
                      </Grid>
                    </Grid>
                  </TabPanel>
                </SwipeableViews>
              </Grid>
            </Grid>
            {OpenEnable ? (
              <ViewProfilePrivileges
                open={OpenEnable}
                callBack={callBackPrivileges}
                idprofile={ProfileID}
              />
            ) : (
              ''
            )}
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
          <Backdrop
            style={{ zIndex: '9999', color: '#FFFFFF' }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
